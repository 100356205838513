import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import { Box, List } from '@mui/material';
import {
  StyledListItemButton,
  StyledNavItemIcon,
  StyledNavItemText,
} from './styles';
import useAuthorized from 'hooks/useAuthorized';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import theme from 'theme';

type DItem = {
  titleKey: string;
  path: string;
  icon: JSX.Element;
  info?: string;
};

export const NavLinkRoute = ({
  data = [],
  role,
  ...other
}: {
  role: string;
  data: DItem[] | [];
}) => {
  const { isAuthorizedRoute } = useAuthorized();
  const { t } = useTranslation();
  return (
    <Box {...other}>
      <List
        disablePadding
        sx={{ p: 1 }}
      >
        {data.map((item: DItem) => {
          const isAuthorized = isAuthorizedRoute(role, item.titleKey);
          if (isAuthorized) {
            return (
              <NavItem
                key={t(item.titleKey)}
                {...item}
              />
            );
          }
        })}
      </List>
    </Box>
  );
};

const NavItem: FC<DItem> = ({ titleKey, path, icon }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const checkPathName = pathname === path;

  return (
    <Box
      sx={{
        background: checkPathName ? theme.palette.grey[200] : '',
      }}
    >
      <StyledListItemButton
        component={RouterLink}
        to={path}
      >
        {icon && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}
        {titleKey && (
          <StyledNavItemText
            disableTypography
            primary={t(titleKey)}
          />
        )}
      </StyledListItemButton>
    </Box>
  );
};
