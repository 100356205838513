import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_DEVICES } from '../../../api/device';

const retrieveDevices = createAsyncThunk(
  'devices/retrieveDevices',
  async (token: string, { rejectWithValue }) => {
    try {
      const devices = await GET_DEVICES(token);
      return devices.result.data;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error retrieveDevices',
      });
    }
  }
);

export default retrieveDevices;
