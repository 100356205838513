import { createSlice } from '@reduxjs/toolkit';
import retrieveUser from './use-case/retrieveUser';
import updateUser from './use-case/updateUser';
const initialState = {};

const { reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveUser.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      const newState = state.map((user) =>
        user.id === payload.result.data.id ? payload.result.data : user
      );
      return [...newState];
    });
  },
});

export default reducer;
