import { Roles } from '../../components/enum/enumRoles';
export const useAuthorized = () => {
  const isAuthorizedUser = (user: any, role?: string) => {
    return user && user.detail_roles.includes(role);
  };

  const isAuthorizedRoute = (role: string, title: string) => {
    let isAuthorizedRoute = true;
    if (role === Roles.USER) {
      if (title === 'collaborators' || title === 'auth0' || title === 'devices')
        isAuthorizedRoute = false;
    }
    if (role === Roles.MANAGER && title === 'auth0') isAuthorizedRoute = false;
    return isAuthorizedRoute;
  };
  return { isAuthorizedUser, isAuthorizedRoute };
};
