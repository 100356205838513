import { createAsyncThunk } from '@reduxjs/toolkit';
import { AssignmentModel } from 'shared/src/assignment.schema';
import AppThunkApiConfig from 'store/thunk-config-api';
import { PATCH_UPDATE_ASSIGNMENT } from 'api/assignment';

interface rejectValueError {
  msg: string;
}

const updateAssignments = createAsyncThunk<
  AssignmentModel,
  { token: string; body: AssignmentModel },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>(
  'assignments/updateAssignments',
  async ({ token, body }, { rejectWithValue }) => {
    try {
      const assignment = await PATCH_UPDATE_ASSIGNMENT(token, body);
      return assignment;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error updateAssignments',
      });
    }
  }
);

export default updateAssignments;
