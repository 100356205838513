import { createSlice } from '@reduxjs/toolkit';
import retrieveAssignments from './use-case/retrieveAssignments';
import createAssignments from './use-case/createAssignments';
import updateAssignments from './use-case/updateAssignments';
const initialState = [];

const { reducer } = createSlice({
  name: 'assignments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveAssignments.fulfilled, (state, { payload }) => {
      return [...payload];
    });
    builder.addCase(updateAssignments.fulfilled, (state, { payload }) => {
      const newState = state.map((assignment) => {
        return assignment.id === payload?.result?.data.id
          ? payload.result.data
          : assignment;
      });
      return [...newState];
    });
    builder.addCase(createAssignments.fulfilled, (state, { payload }) => {
      if (payload.result.status === 'writeFileSuccess') {
        return [payload.result.data, ...state];
      }
    });
  },
});

export default reducer;
