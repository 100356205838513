import { createAsyncThunk } from '@reduxjs/toolkit';
import AppThunkApiConfig from 'store/thunk-config-api';
import { PATCH_UPDATE_DEVICE_IN_CATALOGUE } from 'api/catalogue';

interface rejectValueError {
  msg: string;
}

const updateCatalogDevice = createAsyncThunk<
  any,
  { token: string; body: any },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>(
  'catalog/updateCatalogDevice',
  async ({ token, body }, { rejectWithValue }) => {
    try {
      const device = await PATCH_UPDATE_DEVICE_IN_CATALOGUE(
        token,
        body.id,
        body
      );
      return device;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error updateDevices',
      });
    }
  }
);

export default updateCatalogDevice;
