import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardDevice from '../../../../device';
import { StatusDevices } from 'components/enum/enumDevice';
import { CatalogueDeviceModel } from 'shared/src/catalogue.schema';
import { Typography } from '@mui/material';

interface ICatalogComponent {
  data: CatalogueDeviceModel[];
}
const CatalogComponent: FC<ICatalogComponent> = ({ data }) => {
  return (
    <Box sx={{ mb: 5 }}>
      <Grid
        sx={{
          padding: 1,
          maxWidth: '1200px',
          height: 'calc(100vh - 300px)',
          overflow: 'auto',
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            background: '#fff',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#fff',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#fff',
          },
        }}
        container
        spacing={{ xs: 2, md: 4 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {data.length > 0 ? (
          data.map(
            (device: CatalogueDeviceModel) =>
              String(device.status) === StatusDevices.AVAILABLE && (
                <Grid
                  item
                  xs={2}
                  sm={3}
                  md={3}
                  key={device.id}
                >
                  <CardDevice device={device} />
                </Grid>
              )
          )
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              pt: 10,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  pt: 5,
                }}
              >
                <Box
                  component="img"
                  alt="Device Picture"
                  src={`/assets/empty-data.jpg`}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body1">NO DATA</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default CatalogComponent;
