import { useSelector } from 'react-redux';
import { AssignmentModel } from 'shared/src/assignment.schema';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { DeviceModel } from 'shared/src/device.schema';
import { selectAssignments } from 'store/assignments/assignments.selectors';
import { selectCollaboratorByAuth0Id } from 'store/collaborators/collaborators.selectors';
import { selectFilteredDevices } from 'store/devices/devices.selectors';
import { selectUser } from 'store/user/user.selectors';

export const useGetMyDevices = (keyword: string): any => {
  const user: any = useSelector(selectUser);
  const devices: DeviceModel[] = useSelector((state) =>
    selectFilteredDevices(state, keyword)
  );

  const assignments: AssignmentModel[] = useSelector(selectAssignments);
  const collaborator: CollaboratorModel = useSelector((state) =>
    selectCollaboratorByAuth0Id(state, user?.sub)
  );

  const devicesAssignments = assignments.filter((assignment: AssignmentModel) =>
    collaborator?.id ? assignment.user_id === collaborator.id : false
  );

  const myDevices = devicesAssignments.map((assignment: AssignmentModel) => {
    const device = devices.find(
      (device: DeviceModel) => device.id === assignment?.device_id
    );

    return {
      device,
      assignment: assignment,
    };
  });

  return myDevices;
};
