import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Container maxWidth="sm">
        <Typography variant="h1">404</Typography>
        <Typography variant="h6">{t('notFoundPage:namePage')}</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          mt={5}
        >
          <Button variant="contained">
            <Link
              to={'/'}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {t('notFoundPage:actionBtnGoHome')}
            </Link>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFoundPage;
