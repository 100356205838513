import { useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Collapse, Container, IconButton, Stack } from '@mui/material';

import { Drawer } from 'components/sections/dashboard/_common/drawer';
import { AssignmentsForm } from 'components/sections/dashboard/assignments/assignmentsForm';
import { DeviceForm } from 'components/sections/dashboard/devices/devices/deviceForm';
import DeviceTable from 'components/sections/dashboard/devices/devices/deviceTable/DeviceTable';

import { AppDispatch } from 'store/store';
import updateDevices from 'store/devices/use-case/updateDevices';
import createDevices from 'store/devices/use-case/createDevices';
import createAssignments from 'store/assignments/use-case/createAssignments';
import updateAssignments from 'store/assignments/use-case/updateAssignments';
import { selectUser } from 'store/user/user.selectors';
import { SearchBar } from 'components/searchBar';
import { selectRole } from 'store/role/role.selectors';
import CloseIcon from '@mui/icons-material/Close';
import { AssignmentModel } from 'shared/src/assignment.schema';

import { useGetDevices } from '../../hooks/devices';
import ProtectedRoute from 'components/protectedRoute';
import { Roles } from 'components/enum/enumRoles';
import { IEvent } from 'components/searchBar/SearchBar';
import TableHeader from 'components/tables/table/tableHeader';
import { IFormSelect, INewDeviceData } from 'domain/devices';

import { useTranslation } from 'react-i18next';
import { DeviceStatus } from 'shared/src/device.schema';
import { TableRowDataNotFound } from 'components/_Table';

const legend = [
  { id: 'agency', labelKey: 'label_agency', alignRight: false },
  { id: 'name', labelKey: 'label_name', alignRight: false },
  { id: 'serial_number', labelKey: 'label_serialNumber', alignRight: false },
  { id: 'status', labelKey: 'label_status', alignRight: false },
  { id: 'details', labelKey: 'label_details' },
  { id: 'assignment', labelKey: 'label_assignment' },
  { id: 'action', labelKey: 'label_action' },
];

const DevicesPage: FC = () => {
  const user = useSelector(selectUser);
  const role: string = useSelector(selectRole);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();
  const [keyword, setKeyword] = useState<string>('');
  const devices = useGetDevices(keyword);

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [openAlert, setOpenAlert] = useState<string>('');

  const [formSelect, setFormSelect] = useState<IFormSelect>({
    form: 'ASSIGNMENT',
    formWithData: '',
    method: '',
  });

  const toggleDrawer = (open: boolean, method: string, id?: string) => {
    setFormSelect({
      form: 'DEVICE',
      method: method,
      formWithData: id,
    });

    setOpenDrawer(open);
  };

  const toggleDrawerAssignment = (
    open: boolean,
    method: string,
    id?: string
  ) => {
    setFormSelect({
      form: 'ASSIGNMENT',
      method: method,
      formWithData: id,
    });

    setOpenDrawer(open);
  };

  const resetState = () => {
    setOpenDrawer(false);
  };

  const onSubmitAssignment = async (data: AssignmentModel) => {
    const token = await getAccessTokenSilently();
    if (formSelect.method === 'POST') {
      const assignmentDate = {
        ...data,
        manager_auth0id: user.sub,
        manager_first_name: user.given_name,
        manager_last_name: user.family_name,
      };
      dispatch(
        createAssignments({
          token,
          body: assignmentDate,
        })
      );
    }

    if (formSelect.method === 'DELETE') {
      dispatch(
        updateAssignments({
          token,
          body: data,
        })
      );
    }

    resetState();
  };

  const onChangeFilter = (event: IEvent) => {
    const value = event.target.value.toLowerCase();
    setKeyword(value);
  };

  const onSubmit = async (data: INewDeviceData) => {
    const token = await getAccessTokenSilently();
    const postFormData = {
      agency: data.agency,
      model: data.model,
      status: data.status as DeviceStatus,
      serialnumber: data.serialnumber,
      purchase_date: data.purchase_date,
      received_date: data.received_date,
      exit_date: data.exit_date,
      details: {
        id: data.detailsId,
        name: data.name,
        price: Number(data.price),
        picture: data.picture || null,
        display: Number(data.display),
        storage: Number(data.storage),
        cpu: data.cpu,
        ram: Number(data.ram),
        keyboard: data.keyboard,
        description: data.description,
      },
    };

    const patchFormData = {
      id: data.id,
      ...postFormData,
    };

    if (formSelect.method === 'POST') {
      const result = await dispatch(
        createDevices({
          token,
          body: postFormData,
        })
      );
      if (result.payload.result.status === 'dataAlreadyExist') {
        setOpenAlert(result.payload.msg);
      }
    }
    if (formSelect.method === 'PATCH') {
      dispatch(
        updateDevices({
          token,
          body: patchFormData,
        })
      );
    }

    resetState();
  };

  const { t } = useTranslation();

  return (
    <ProtectedRoute role={Roles.MANAGER}>
      <Container
        sx={{
          textAlign: 'initial',
        }}
      >
        <TableHeader
          icon={'navbar/ic_devices'}
          title={t('label_devices')}
          role={role}
          CTA={{
            text: t('label_newDevice'),
            action: () => toggleDrawer(true, 'POST'),
          }}
        />
        <SearchBar
          placeholder={t('label_searchDevicePage')}
          onChangeFilter={onChangeFilter}
        />

        {openAlert != '' && (
          <Stack padding={2}>
            <Collapse in={!!openAlert}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert('');
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {openAlert}
              </Alert>
            </Collapse>
          </Stack>
        )}

        {devices.length > 0 ? (
          <DeviceTable
            devices={devices}
            legend={legend}
            toggleDrawer={toggleDrawer}
            toggleDrawerAssignment={toggleDrawerAssignment}
          />
        ) : (
          <TableRowDataNotFound legend={legend} />
        )}

        <Drawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          component={
            formSelect.form === 'DEVICE' ? (
              <DeviceForm
                details={formSelect}
                onSubmit={onSubmit}
              />
            ) : (
              <AssignmentsForm
                details={formSelect}
                setOpenDrawer={setOpenDrawer}
                onSubmit={onSubmitAssignment}
              />
            )
          }
        />
      </Container>
    </ProtectedRoute>
  );
};

export default DevicesPage;
