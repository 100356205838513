import React from 'react';
import { StyledLabel } from './styles';
export type StatusProps =
  | 'DEFAULT'
  | 'AVAILABLE'
  | 'UNAVAILABLE'
  | 'OUT_OF_STOCK'
  | 'ASSIGN'
  | 'NOT_ASSIGN'
  | 'ORDERED'
  | 'ACTIVE'
  | 'NOT_ACTIVE'
  | 'RECEIVED';

type LabelProps = {
  children: React.ReactNode;
  status: StatusProps;
};

const Label = ({ children, status = 'DEFAULT' }: LabelProps) => {
  return (
    <StyledLabel
      component="span"
      ownerState={{ status }}
    >
      {children}
    </StyledLabel>
  );
};

export default Label;
