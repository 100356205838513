import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_COLLABORATORS } from '../../../api/collaborator';

const retrieveCollaborators = createAsyncThunk(
  'collaborators/retrieveCollaborators',
  async (token: string, { rejectWithValue }) => {
    try {
      const collaborators = await GET_COLLABORATORS(token);
      return collaborators.result.data;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error retrieveCollaborators',
      });
    }
  }
);

export default retrieveCollaborators;
