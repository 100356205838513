import { FC } from 'react';
import { Box, Collapse, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DeviceModelWithAssign } from '@domain/devices';
import { StyledBoxContainer } from './styles';

interface ITableRowDetails {
  details: DeviceModelWithAssign['details'];
  openDetails: boolean;
}

const TableRowDetails: FC<ITableRowDetails> = ({ details, openDetails }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell
        style={{ padding: 0 }}
        colSpan={7}
      >
        <Collapse
          in={Boolean(openDetails)}
          timeout="auto"
          unmountOnExit
        >
          <StyledBoxContainer>
            <Typography variant="h6">{t('label_details')}</Typography>

            {Object.entries(details).map(([key, value]) => {
              return (
                key !== 'picture' && (
                  <Box
                    key={key}
                    sx={{
                      typography: 'subtitle2',
                    }}
                  >
                    {t(`label_${key}`)} : {value?.toString()}
                  </Box>
                )
              );
            })}
          </StyledBoxContainer>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default TableRowDetails;
