import { NavLink as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { StyledNavItem, StyledNavItemIcon, StyledNavItemText } from './styles';
import useAuthorized from 'hooks/useAuthorized';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type DItem = {
  titleKey: string;
  path: string;
  icon: JSX.Element;
  info?: string;
};

export const NavMenuRoute = ({
  data = [],
  role,
}: {
  role: string;
  data: DItem[] | [];
}) => {
  const { isAuthorizedRoute } = useAuthorized();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: 1,
        display: 'flex',
      }}
    >
      {data
        .filter((element: DItem) => element.titleKey !== 'label_dashboard')
        .map((item: DItem) => {
          const isAuthorized = isAuthorizedRoute(role, item.titleKey);
          if (isAuthorized) {
            return (
              <NavItem
                key={t(item.titleKey)}
                {...item}
              />
            );
          }
        })}
    </Box>
  );
};

const NavItem: FC<DItem> = (props) => {
  const { titleKey, path, icon } = props;
  const { t } = useTranslation();
  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
      >
        {icon && <StyledNavItemIcon>{icon}</StyledNavItemIcon>}
        {titleKey && <StyledNavItemText>{t(titleKey)}</StyledNavItemText>}
      </StyledNavItem>
    </>
  );
};
