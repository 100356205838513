import { Grid, Typography } from '@mui/material';
import { NavMenuRoute } from '../../../../../components/navMenuRoute';
import navConfig from '../../../../../layouts/dashboard/nav/config';
import { FC } from 'react';

interface IHomeMenu {
  role: string;
}

const HomeMenu: FC<IHomeMenu> = ({ role }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        maxWidth: '1200px',
        height: 'calc(100vh - 150px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* on affiche ce message jusqu'on deploie et on ajoute le role automatiquement apres le 1er login apartir de auth0 */}
      {!role ? (
        <Typography
          variant="h4"
          color="grey"
        >
          Hello Welcome to DSI dashboard, if you are here because you are not
          yet created as a collaborator in the application, Please go ask your
          manager to add you on the list.
        </Typography>
      ) : (
        <NavMenuRoute
          data={navConfig}
          role={role && role}
        />
      )}
    </Grid>
  );
};

export default HomeMenu;
