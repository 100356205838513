import { ForwardedRef, forwardRef } from 'react';
import { Box } from '@mui/material';

const SvgColor = (
  { src, sx, ...other }: { src: string; sx: object },
  ref: ForwardedRef<HTMLDivElement>
) => {
  return (
    <Box
      component="span"
      className="svg-color"
      ref={ref}
      sx={{
        width: 24,
        height: 24,
        display: 'inline-block',
        bgcolor: 'currentColor',
        mask: `url(${src}) no-repeat center / contain`,
        WebkitMask: `url(${src}) no-repeat center / contain`,
        ...sx,
      }}
      {...other}
    />
  );
};

export default forwardRef(SvgColor);
