import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  TableRow,
  TableCell,
  Stack,
  Popover,
  MenuItem,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { CatalogueDeviceModel } from 'shared/src/catalogue.schema';
import { Label } from 'components/_Input';

import { TableRowDetails } from 'components/_Table';

interface CatalogRowProps {
  row: CatalogueDeviceModel;
  toggleDrawer: (
    state: boolean,
    formName: string,
    row: CatalogueDeviceModel
  ) => void;
}

const CatalogRow: FC<CatalogRowProps> = ({ row, toggleDrawer }) => {
  const { id, model, details, status } = row;
  const [openDetails, setOpenDetails] = useState(false);
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleOpenFormDevice = () => {
    toggleDrawer(true, 'PATCH', row);
    setOpen(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
        >
          <Stack
            direction="row"
            spacing={2}
          >
            <Box
              component="img"
              sx={{
                height: 32,
                width: 32,
                maxHeight: { xs: 32, md: 32 },
                maxWidth: { xs: 32, md: 32 },
              }}
              alt="Device Picture"
              src={`/assets/${model}.png`}
            />
          </Stack>
        </TableCell>

        <TableCell align="left">{details.name}</TableCell>

        <TableCell align="left">{t(`value_${model}`)}</TableCell>

        <TableCell align="left">
          <Label status={status}>{t(`value_${status}`)}</Label>
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <IconButton
            size="large"
            color="inherit"
            onClick={handleOpenMenu}
          >
            <DragIndicatorIcon></DragIndicatorIcon>
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRowDetails
        details={details}
        openDetails={openDetails}
      />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            minWidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleOpenFormDevice}>{t('label_edit')}</MenuItem>
      </Popover>
    </>
  );
};
export default CatalogRow;
