import { createAsyncThunk } from '@reduxjs/toolkit';
import AppThunkApiConfig from 'store/thunk-config-api';
import { POST_CREATE_DEVICE } from '../../../api/device';

interface rejectValueError {
  msg: string;
}

const createDevices = createAsyncThunk<
  any | any,
  { token: string; body: any },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>('devices/createDevices', async ({ token, body }, { rejectWithValue }) => {
  try {
    const devices = await POST_CREATE_DEVICE(token, body);
    return devices;
  } catch (e) {
    return rejectWithValue({
      msg: 'Error createDevices',
    });
  }
});

export default createDevices;
