import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_DEVICES_IN_CATALOGUE } from '../../../api/catalogue';

const retrieveCatalogue = createAsyncThunk(
  'catalog/retrieveCatalogue',
  async (token: string, { rejectWithValue }) => {
    try {
      const devices = await GET_DEVICES_IN_CATALOGUE(token);
      return devices.result.data;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error retrieveCatalogue',
      });
    }
  }
);

export default retrieveCatalogue;
