import { styled, alpha } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledLabel = styled((props) => <Box {...props} />)(
  ({ theme, ownerState }) => {
    const backgroundColorStatus = {
      ...(ownerState.status === 'DEFAULT' && {
        color: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.main, 0.16),
      }),
      ...(ownerState.status === 'AVAILABLE' && {
        color: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, 0.16),
      }),
      ...(ownerState.status === 'UNAVAILABLE' && {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.16),
      }),
      ...(ownerState.status === 'ASSIGN' && {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.16),
      }),
      ...(ownerState.status === 'NOT_ASSIGN' && {
        color: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.main, 0.16),
      }),
      ...(ownerState.status === 'ORDERED' && {
        color: theme.palette.warning.main,
        backgroundColor: alpha(theme.palette.warning.main, 0.16),
      }),
      ...(ownerState.status === 'RECEIVED' && {
        color: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.main, 0.16),
      }),
      ...(ownerState.status === 'ACTIVE' && {
        color: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, 0.16),
      }),
      ...(ownerState.status === 'NOT_ACTIVE' && {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.16),
      }),
      ...(ownerState.status === 'OUT_OF_STOCK' && {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.16),
      }),
    };

    return {
      height: 24,
      minWidth: 22,
      lineHeight: 0,
      borderRadius: 6,
      cursor: 'default',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      justifyContent: 'center',
      textTransform: 'capitalize',
      padding: theme.spacing(0, 1),
      color: theme.palette.grey[800],
      fontSize: theme.typography.pxToRem(12),
      fontFamily: theme.typography.fontFamily,
      backgroundColor: theme.palette.grey[200],
      fontWeight: theme.typography.fontWeightBold,
      ...backgroundColorStatus,
    };
  }
);
