import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resourcesEnCA from './locales/en_CA_translation.json';
import resourcesFrCA from './locales/fr_CA_translation.json';

const resources = {
  en_CA: {
    translation:
    {
      ...resourcesEnCA
    }
  },
  fr_CA: {
    translation:
    {
      ...resourcesFrCA
    }
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr_CA',
    resources,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['localStorage', 'cookie'],
    },
  });
