import { Auth0Provider } from '@auth0/auth0-react';
import { config } from '../../config';

interface IPropsAuthProvider {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: IPropsAuthProvider) => {
  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      authorizationParams={{
        redirect_uri: config.auth0CallbackUrl,
        connection: config.auth0PublicConnection,
        audience: config.auth0Audience,
        scope: 'read:current_user openid profile email',
        prompt: 'login',
      }}
      cacheLocation={'localstorage'}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
