import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotAuthorizedPage = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        height: 'calc(100vh - 64px);',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h1">{t('label_unauthorized_title')}</Typography>
      <Typography>{t('label_unauthorized_description')}</Typography>
      <Box mt={5}>
        <Button variant="contained">
          <Link
            to={'/'}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {t('label_unauthorized_button')}
          </Link>
        </Button>
      </Box>
    </Container>
  );
};

export default NotAuthorizedPage;
