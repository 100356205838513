import i18n from 'i18next';

export const descendingComparator = (
  a: { [x: string]: number },
  b: { [x: string]: number },
  orderBy: string | number
) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: string, orderBy: string | number) => {
  return order === 'desc'
    ? (a: { [x: string]: number }, b: { [x: string]: number }) =>
        descendingComparator(a, b, orderBy)
    : (a: { [x: string]: number }, b: { [x: string]: number }) =>
        -descendingComparator(a, b, orderBy);
};

export const applySortTableFilter = <ArgType extends { [s: string]: unknown }>(
  array: Array<ArgType>,
  inputValue: string
): Array<ArgType> => {
  return array.filter((item: ArgType) => {
    return Object.entries(item).some(([key, val]) => {
      if (key === 'status' || key === 'model') {
        return isMatchWithFilter({
          inputValue,
          checkValue: String(i18n.t(`value_${val}`)),
        });
      } else {
        return isMatchWithFilter({ inputValue, checkValue: val });
      }
    });
  });
};

const isMatchWithFilter = ({
  checkValue,
  inputValue,
}: {
  checkValue: unknown;
  inputValue: string;
}): boolean => {
  if (typeof checkValue === 'string' || typeof checkValue === 'number') {
    return String(checkValue).toLowerCase().includes(inputValue.toLowerCase());
  }
  if (
    checkValue &&
    typeof checkValue === 'object' &&
    !Array.isArray(checkValue)
  ) {
    return Object.values(checkValue).some((propDetail) => {
      return isMatchWithFilter({ checkValue: propDetail, inputValue });
    });
  }
  return false;
};
