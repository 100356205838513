import { Box, Button, CardMedia, Container, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from "react-i18next";

interface ILoginPage {
  state: boolean;
  setState: (val: boolean) => void;
}
const LoginPage: FC<ILoginPage> = ({ state, setState }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Container
        sx={{
          textAlign: 'initial',
          height: '70vh',
          padding: 3,
          borderRadius: 2,
          boxShadow: 5,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '150px',
            }}
            component="img"
            alt={'logo Zenika'}
            src={'/assets/icons/logo-zenika.svg'}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '40vh',
          }}
        >
          <Box
            sx={{
              height: '100vh',
              width: '50%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardMedia
              component="img"
              image={`/assets/icons/pages/loginPage.png`}
              alt="device picture"
            />
          </Box>
          <Box
            sx={{
              pl: 5,
              width: '40%',
            }}
          >
            <Typography
              variant="h2"
              color="grey"
            >
              {t("label_title")}
            </Typography>
            <Typography
              variant="body1"
              color="grey"
            >
              {t("label_application_description")}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                disableElevation
                onClick={() => setState(!state)}
              >
                {t("label_launch_button")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LoginPage;
