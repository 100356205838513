import { createAsyncThunk } from '@reduxjs/toolkit';
import AppThunkApiConfig from 'store/thunk-config-api';
import { POST_CREATE_DEVICE_IN_CATALOGUE } from 'api/catalogue';
import { CatalogueDeviceSchema } from 'shared/src/catalogue.schema';

interface rejectValueError {
  msg: string;
}

const createDeviceCatalog = createAsyncThunk<
  any | CatalogueDeviceSchema,
  { token: string; body: CatalogueDeviceSchema },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>(
  'catalog/createDeviceCatalog',
  async ({ token, body }, { rejectWithValue }) => {
    try {
      const devices = await POST_CREATE_DEVICE_IN_CATALOGUE(token, body);
      return devices;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error createDeviceCatalog',
      });
    }
  }
);

export default createDeviceCatalog;
