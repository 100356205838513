import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow, TableCell, TableSortLabel } from '@mui/material';
import { StyledTableHead } from './styles';
import { ILegendTableHead } from 'components/_Table/types';

interface ITableHeadRowProps {
  headLabel: ILegendTableHead[];
}

const TableHeadRow: FC<ITableHeadRowProps> = ({ headLabel }) => {
  const { t } = useTranslation();

  return (
    <StyledTableHead>
      <TableRow>
        {headLabel.map((headCell: ILegendTableHead) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
          >
            <TableSortLabel hideSortIcon>{t(headCell.labelKey)}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

export default TableHeadRow;
