import { CollaboratorModel } from 'shared/src/collaborator.schema';
import type { RootState } from '../store';

export const selectCollaborators = (state: RootState) => {
  return state.collaborators;
};

export const selectCollaboratorByAuth0Id = (state: RootState, id: string) => {
  return state.collaborators.find(
    (collaborator: CollaboratorModel) => collaborator.auth0Id === id
  );
};

export const selectCollaboratorByUserId = (state: RootState, id: number) => {
  return state.collaborators.find(
    (collaborator: CollaboratorModel) => collaborator.id === Number(id)
  );
};
