import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import AuthProvider from './providers/authProvider';
import App from './App';
import AuthenticationGuard from './components/wrappers/authenticationGuard';
import { CssBaseline } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './store/store';
import theme from './theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <ToastContainer position="bottom-center" />
    <AuthProvider>
      <Provider store={store}>
        <AuthenticationGuard component={App} />
      </Provider>
    </AuthProvider>
  </ThemeProvider>
);
