import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import { AppDispatch } from 'store/store';
import { useDispatch } from 'react-redux';

import useResponsive from 'hooks/useResponsive';
import retrieveDevices from 'store/devices/use-case/retrieveDevices';
import retrieveCollaborators from 'store/collaborators/use-case/retrieveCollaborators';
import retrieveCatalogue from 'store/catalogue/use-case/retrieveCatalogue';
import retrieveAssignments from 'store/assignments/use-case/retrieveAssignments';
import retrieveUser from 'store/user/use-case/retrieveUser';
import retrieveRole from 'store/role/use-case/retrieveRole';
import { Header } from 'layouts/dashboard/header';
import { Nav } from 'layouts/dashboard/nav';

const InitLayout = () => {
  const [open, setOpen] = useState<boolean>(false);
  const isDesktop = useResponsive('up', 'lg');
  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      dispatch(retrieveDevices(token));
      dispatch(retrieveCatalogue(token));
      dispatch(retrieveUser(user));
      dispatch(retrieveRole(user?.detail_roles[0]));
      dispatch(retrieveCollaborators(token)),
        dispatch(retrieveAssignments(token));
    })();
  }, []);

  const Main = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        ...(isDesktop && {
          marginLeft: '280px',
        }),
      }}
    >
      <Header onOpenNav={() => setOpen(true)} />
      {user?.detail_roles.length > 0 && (
        <Nav
          user={user}
          openNav={open}
          onCloseNav={() => setOpen(false)}
        />
      )}
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
};

export default InitLayout;
