import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { FC } from 'react';
import { StyledTableHead } from './styles';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

interface IHeadLabel {
  alignRight: boolean;
  id: string;
  labelKey: string;
}
const TableHeadRow: FC<any> = ({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}) => {
  const createSortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const { t } = useTranslation();

  return (
    <StyledTableHead>
      <TableRow>
        {headLabel.map((headCell: IHeadLabel) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {t(headCell.labelKey)}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};

TableHeadRow.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default TableHeadRow;
