import { FC, useState } from 'react';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import { CatalogRow } from '../catalogRow';
import { IEvent } from 'components/searchBar/SearchBar';
import { TableHeadRow } from 'components/tables/table/tableHeadRow';
import { CatalogueDeviceModel } from 'shared/src/catalogue.schema';
import { ILegendTableHead } from 'components/_Table/types';

interface ICatalogTable {
  dataRows: CatalogueDeviceModel[];
  legend: ILegendTableHead[];
  toggleDrawer: (
    open: boolean,
    method: string,
    row: CatalogueDeviceModel | null
  ) => void;
}

const CatalogTable: FC<ICatalogTable> = ({
  dataRows,
  toggleDrawer,
  legend,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const dataList = dataRows || [];

  const handleRequestSort = (event: IEvent, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHeadRow
            order={order}
            orderBy={orderBy}
            headLabel={legend}
            rowCount={dataList.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {dataList.map((row: CatalogueDeviceModel) => (
              <CatalogRow
                key={row.id}
                toggleDrawer={toggleDrawer}
                row={row}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default CatalogTable;
