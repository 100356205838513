import { AssignmentModel } from 'shared/src/assignment.schema';
import type { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const selectAssignments = (state: RootState) => state.assignments;
export const selectDevices = (state: RootState) => state.devices;

export const selectAssignmentById = (
  state: RootState,
  assignmentId: number | undefined
) => {
  return state.assignments.find(
    (assignment: AssignmentModel) => assignment.id === assignmentId
  );
};

export const selectAssignmentsByDeviceId = (
  assignmentDeviceId: number | undefined
) =>
  createSelector(selectAssignments, (assignments) => {
    const filteredAssignment = assignments.filter(
      (assignment: AssignmentModel) => {
        return assignment.device_id === assignmentDeviceId;
      }
    );

    return filteredAssignment;
  });
