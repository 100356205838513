import { FC, SetStateAction, useState } from 'react';
import { Card, Table, TableBody } from '@mui/material';

import { MyDeviceRow } from '../myDeviceRow';
import { TableHeadRow } from 'components/tables/table/tableHeadRow';
import { MYDeviceModelWithAssign } from 'domain/devices';
import { ILegendTableHead } from '@components/_Table/types';

export interface DeviceTableProps {
  devices: MYDeviceModelWithAssign[];
  legend: ILegendTableHead[];
}

const DeviceTable: FC<DeviceTableProps> = ({ devices, legend }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);

  const handleRequestSort = (_: unknown, property: SetStateAction<null>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card>
      <Table>
        <TableHeadRow
          order={order}
          orderBy={orderBy}
          headLabel={legend}
          rowCount={devices.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {devices.map((row: MYDeviceModelWithAssign) => (
            <MyDeviceRow
              key={row.device.id}
              row={row}
            />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};
export default DeviceTable;
