import { Container } from '@mui/material';
import { useGetMyDevices } from 'hooks/devices';
import { MyDeviceTable } from 'components/sections/dashboard/devices/myDevices/myDeviceTable';
import TableHeader from 'components/tables/table/tableHeader';
import { MYDeviceModelWithAssign } from 'domain/devices';
import { useTranslation } from 'react-i18next';
import { TableRowDataNotFound } from 'components/_Table';

const MyDevicesPage = () => {
  const devices: MYDeviceModelWithAssign[] = useGetMyDevices('');
  const { t } = useTranslation();
  const legend = [
    { id: 'agency', labelKey: 'label_agency', alignRight: false },
    { id: 'name', labelKey: 'label_deviceName', alignRight: false },
    { id: 'serial_number', labelKey: 'label_serialNumber', alignRight: false },
    { id: 'status', labelKey: 'label_status', alignRight: false },
    {
      id: 'assignment_start_date',
      labelKey: 'label_assignmentStartDate',
      alignRight: false,
    },
    {
      id: 'assignment_end_date',
      labelKey: 'label_assignmentEndDate',
      alignRight: false,
    },
    { id: 'details', labelKey: 'label_details' },
  ];

  const hasDevices =
    devices.length > 0 && devices.every((device) => !!device.device);

  return (
    <Container
      sx={{
        textAlign: 'initial',
      }}
    >
      <TableHeader
        icon={'navbar/ic_device'}
        title={t('label_mydevices')}
      />
      {hasDevices ? (
        <MyDeviceTable
          devices={devices}
          legend={legend}
        />
      ) : (
        <TableRowDataNotFound legend={legend} />
      )}
    </Container>
  );
};

export default MyDevicesPage;
