import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Link, Drawer, Typography, Avatar } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import navConfig from './config';
import { NavLinkRoute } from '../../../components/navLinkRoute';
import useResponsive from '../../../hooks/useResponsive';
import { useSelector } from 'react-redux';
import { selectRole } from 'store/role/role.selectors';
import { StyledBox } from './styles';
import { useTranslation } from 'react-i18next';

const NAV_WIDTH = 280;

export const Nav = ({
  user,
  openNav,
  onCloseNav,
}: {
  user: any;
  openNav: boolean;
  onCloseNav: () => void;
}) => {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const role: string = useSelector(selectRole);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const { t } = useTranslation();

  const renderContent = (
    <>
      <Box
        sx={{
          mb: 1,
          display: 'inline-flex',
          justifyContent: 'center',
        }}
      >
        <LinkRouter to="/">
          <Box
            component="img"
            alt={'logo Zenika'}
            src={'/assets/icons/logo-zenika.svg'}
            sx={{ width: '68px' }}
          />
        </LinkRouter>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledBox>
            <Avatar
              src={user?.picture}
              alt="photoURL"
            />

            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: 'text.primary' }}
              >
                {user?.name}
              </Typography>

              <Typography
                variant="body2"
                sx={{ color: 'text.secondary' }}
              >
                {t(`value_${role.toLowerCase()}`)}
              </Typography>
            </Box>
          </StyledBox>
        </Link>
      </Box>
      <NavLinkRoute
        data={navConfig}
        role={role && role}
      />
      <Box sx={{ flexGrow: 1 }} />
    </>
  );

  return isDesktop ? (
    <Drawer
      open
      variant="permanent"
      PaperProps={{
        sx: {
          width: NAV_WIDTH,
          borderRightStyle: 'dashed',
        },
      }}
    >
      {renderContent}
    </Drawer>
  ) : (
    <Drawer
      open={openNav}
      onClose={onCloseNav}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        sx: { width: NAV_WIDTH },
      }}
    >
      {renderContent}
    </Drawer>
  );
};
