import { FC } from 'react';
import {
  Box,
  Table,
  TableCell,
  TableRow,
  Typography,
  Card,
  TableContainer,
  TableBody,
} from '@mui/material';
import { ILegendTableHead } from '../types';
import { TableHead } from './TableHead';

interface ITableRowDataNotFound {
  legend: ILegendTableHead[];
}

const TableRowDataNotFound: FC<ITableRowDataNotFound> = (props) => {
  const { legend } = props;

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead headLabel={legend} />
          <TableBody>
            <TableRow>
              <TableCell colSpan={legend.length}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 5,
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 100,
                      width: 100,
                    }}
                    alt="Device Picture"
                    src={`/assets/empty-data.jpg`}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="body1">NO DATA</Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default TableRowDataNotFound;
