import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { selectCollaboratorByAuth0Id } from 'store/collaborators/collaborators.selectors';
import { selectUser } from 'store/user/user.selectors';
import EditIcon from '@mui/icons-material/Edit';

const ProfilePage = () => {
  const { t } = useTranslation();
  const user: any = useSelector(selectUser);
  const collaborator: CollaboratorModel = useSelector((state) =>
    selectCollaboratorByAuth0Id(state, user?.sub)
  );

  return (
    <Container
      sx={{
        textAlign: 'initial',
      }}
    >
      <Grid
        sx={{
          height: 'calc(100vh - 400px)',
          pt: 12,
          boxShadow: 2,
        }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid
          item
          xs={6}
        >
          <Box
            sx={{
              pt: 4,
              mb: 5,
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ typography: { sm: 'h3', xs: 'h4' } }}>
              {t('label_profile')}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
            }}
          >
            <Avatar
              src={collaborator?.picture}
              sx={{
                height: 300,
                width: 300,
                maxHeight: { xs: 400, md: 300 },
                maxWidth: { xs: 300, md: 300 },
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Box
            sx={{
              padding: 5,
              borderRadius: 2,
              minHeight: 520,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
              }}
            >
              <Typography
                sx={{ fontSize: 30, mb: 5 }}
                color="text.secondary"
                gutterBottom
              >
                {t('label_details')}
              </Typography>
              <IconButton
                color="inherit"
                onClick={() => console.log()}
              >
                <EditIcon />
              </IconButton>
            </Box>

            <Typography
              sx={{ typography: { sm: 'h5', xs: 'h5' }, mb: 2 }}
              color="text.secondary"
            >
              {t('label_name')}: {collaborator?.firstName}
              {''} {collaborator?.lastName}
            </Typography>
            <Typography
              sx={{ typography: { sm: 'h5', xs: 'h5' }, mb: 2 }}
              color="text.secondary"
            >
              Email: {collaborator?.email}
            </Typography>
            <Typography
              sx={{ typography: { sm: 'h5', xs: 'h5' }, mb: 2 }}
              color="text.secondary"
            >
              {t('label_agency')}: {collaborator?.agency}
            </Typography>
            <Typography
              sx={{ typography: { sm: 'h5', xs: 'h5' }, mb: 2 }}
              color="text.secondary"
            >
              {t('label_onboardingDate')}: {collaborator?.onboarding_date}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProfilePage;
