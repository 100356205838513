import { InputAdornment, TextField } from '@mui/material';
import SvgColor from 'components/svgColor';
import { FC } from 'react';

export interface IEvent {
  target: { value: string };
}
interface ISearchBar {
  onChangeFilter: (event: IEvent) => void;
  placeholder: string;
}

const SearchBar: FC<ISearchBar> = ({ onChangeFilter, placeholder }) => {
  return (
    <TextField
      sx={{ width: 350, mb: 4 }}
      placeholder={placeholder}
      onChange={onChangeFilter}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgColor
              src={`/assets/icons/navbar/ic_search.svg`}
              sx={{ width: 25, height: 25 }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default SearchBar;
