if (!process.env.REACT_APP_AUTH0_DOMAIN) {
  throw new Error(
    "ERROR: App couldn't start because AUTH0_DOMAIN isn't defined"
  );
}

if (!process.env.REACT_APP_AUTH0_CLIENTID) {
  throw new Error(
    "ERROR: App couldn't start because AUTH0_CLIENTID isn't defined"
  );
}

if (!process.env.REACT_APP_AUTH0_CALLBACK_URL) {
  throw new Error(
    "ERROR: App couldn't start because AUTH0_CALLBACK_URL isn't defined"
  );
}

if (!process.env.REACT_APP_AUTH0_CONNECTION) {
  throw new Error(
    "ERROR: App couldn't start because REACT_APP_AUTH0_CONNECTION isn't defined"
  );
}

if (!process.env.REACT_APP_AUTH0_AUDIENCE) {
  throw new Error(
    "ERROR: App couldn't start because REACT_APP_AUTH0_AUDIENCE isn't defined"
  );
}

if (!process.env.REACT_APP_SERVER_DOMAIN) {
  throw new Error(
    "ERROR: App couldn't start because REACT_APP_SERVER_DOMAIN isn't defined"
  );
}

export const config = {
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENTID,
  auth0CallbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
  auth0PublicConnection: process.env.REACT_APP_AUTH0_CONNECTION,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  serverDomain: process.env.REACT_APP_SERVER_DOMAIN,
};
