import { TableRow, TableCell } from '@mui/material';

import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { useSelector } from 'react-redux';
import { selectCollaboratorByUserId } from 'store/collaborators/collaborators.selectors';
import { selectDeviceById } from 'store/devices/devices.selectors';
import { DeviceModel } from 'shared/src/device.schema';
import { AssignmentModel } from 'shared/src/assignment.schema';
import { FC } from 'react';
import { Label } from 'components/_Input';

interface DeviceRowProps {
  row: AssignmentModel;
}

const DeviceRow: FC<DeviceRowProps> = ({ row }) => {
  const {
    id,
    user_id,
    manager_first_name,
    manager_last_name,
    device_id,
    description,
    assignment_start_date,
    assignment_end_date,
  } = row;

  const collaborator: CollaboratorModel = useSelector((state) =>
    selectCollaboratorByUserId(state, user_id)
  );
  const device: DeviceModel = useSelector((state) =>
    selectDeviceById(state, device_id)
  );

  const assignmentStatus =
    assignment_end_date === '' ||
    (assignment_end_date != '' &&
      new Date(assignment_end_date) >
        new Date(new Date().toISOString().split('T')[0]));

  return (
    <>
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
      >
        <TableCell align="left">{device.details.name}</TableCell>
        <TableCell align="left">
          {device.serialnumber ? device.serialnumber : 'N/A'}
        </TableCell>
        <TableCell align="left">
          <Label status={assignmentStatus ? 'NOT_ASSIGN' : 'ASSIGN'}>
            {assignmentStatus ? 'Assigned' : 'Unassigned'}
          </Label>
        </TableCell>
        <TableCell align="left">
          {collaborator?.firstName + ' ' + collaborator?.lastName}
        </TableCell>
        <TableCell align="left">{assignment_start_date}</TableCell>
        <TableCell align="left">{assignment_end_date}</TableCell>
        <TableCell align="left">
          {manager_first_name} {manager_last_name}
        </TableCell>
        <TableCell align="left">{description}</TableCell>
      </TableRow>
    </>
  );
};
export default DeviceRow;
