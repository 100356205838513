import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#f3717e',
      main: '#f14e5f',
      dark: '#be1b2c',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          marginTop: '0',
          marginBottom: '1rem',
        },
      },
    },
  },
});

export default theme;
