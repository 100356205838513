import { createAsyncThunk } from '@reduxjs/toolkit';
import { POST_ASSIGN_ROLE_TO_USER } from '../../../api/user';
import AppThunkApiConfig from 'store/thunk-config-api';

interface rejectValueError {
  msg: string;
}

const updateUser = createAsyncThunk<
  any,
  { token: string; body: any },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>('user/updateUser', async ({ token, body }, { rejectWithValue }) => {
  try {
    await POST_ASSIGN_ROLE_TO_USER(token, body.auth0Id, body.role);
  } catch (e) {
    return rejectWithValue({
      msg: 'Error updateUser',
    });
  }
});

export default updateUser;
