import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const AccountPopover = () => {
  const { t } = useTranslation();
  const { logout, user } = useAuth0();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip
        title="Open settings"
        sx={{ mr: 1 }}
      >
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0, ml: 2 }}
        >
          <Avatar
            alt={user?.name}
            src={user?.picture}
            imgProps={{ referrerPolicy: 'no-referrer' }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: 5.5 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Box sx={{ minWidth: '120px' }}>
          <Link
            to="/profile"
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <MenuItem onClick={handleCloseUserMenu}>
              {t('label_profile')}
            </MenuItem>
          </Link>
          <MenuItem onClick={() => logout()}>
            <Typography textAlign="center">{t('label_logout')}</Typography>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
