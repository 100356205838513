import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { FC, useEffect, useState } from 'react';
import LoginPage from 'components/sections/dashboard/homePage/homePage';
import Loader from 'components/Loader';

interface IPropsAuthenticationGuard {
  component: React.ComponentType<object>;
}

const AuthenticationGuard: FC<IPropsAuthenticationGuard> = (props) => {
  const { component } = props;
  const { isAuthenticated, isLoading } = useAuth0();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });
  const [state, setState] = useState(false);
  useEffect(() => {
    setState(isAuthenticated);
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loader />;
  } else {
    if (!state && !isAuthenticated) {
      return (
        <>
          <LoginPage
            state={state}
            setState={setState}
          />
        </>
      );
    }
  }

  return <Component />;
};

export default AuthenticationGuard;
