import { FC } from 'react';
import {
  Box,
  Collapse,
  Container,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledBoxContainer } from './styles';
import { CollaboratorModel } from 'shared/src/collaborator.schema';

interface ITableRowProfile {
  profile: Partial<CollaboratorModel>;
  openDetails: boolean;
}

const TableRowProfile: FC<ITableRowProfile> = ({ profile, openDetails }) => {
  const { t } = useTranslation();

  return (
    <TableRow>
      <TableCell
        style={{ padding: 0 }}
        colSpan={7}
      >
        <Collapse
          in={Boolean(openDetails)}
          timeout="auto"
          unmountOnExit
        >
          <StyledBoxContainer>
            <Typography variant="h6">{t('label_details')}</Typography>

            <Container
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {Object.entries(profile).map(([key, value]) => {
                if (!value) {
                  return null;
                }

                return (
                  key !== 'picture' && (
                    <Box
                      key={key}
                      sx={{
                        typography: 'subtitle2',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '1rem',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        component="img"
                        alt={t(`label_${key}`)}
                        src={`/assets/icons/profile/${key}.svg`}
                        sx={{ width: '50px' }}
                      />
                      <Typography>{value?.toString()}</Typography>
                    </Box>
                  )
                );
              })}
            </Container>
          </StyledBoxContainer>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};

export default TableRowProfile;
