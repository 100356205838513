import SvgColor from '../../../components/svgColor';

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    titleKey: 'label_dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    titleKey: 'label_collaborators',
    path: '/dashboard/collaborators',
    icon: icon('ic_users'),
  },
  {
    titleKey: 'label_catalog',
    path: '/dashboard/catalog',
    icon: icon('ic_catalog'),
  },
  {
    titleKey: 'label_devices',
    path: '/dashboard/devices',
    icon: icon('ic_devices'),
  },
  {
    titleKey: 'label_mydevices',
    path: '/dashboard/my_devices',
    icon: icon('ic_device'),
  },
];

export default navConfig;
