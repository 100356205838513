import { Drawer as DrawerMUI } from '@mui/material';
import { FC } from 'react';

interface IDrawer {
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
  component: JSX.Element;
}

const Drawer: FC<IDrawer> = ({ component, setOpenDrawer, openDrawer }) => {
  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  return (
    <DrawerMUI
      sx={() => ({
        '& .MuiDrawer-paper': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), `,
        },
        '&  .MuiInputBase-root': {},
      })}
      anchor={'right'}
      open={openDrawer}
      onClose={() => toggleDrawer(false)}
    >
      <div
        style={{
          position: 'relative',
          width: '600px',
          height: '100vh',
        }}
      >
        {component}
      </div>
    </DrawerMUI>
  );
};
export default Drawer;
