import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { TextField, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {
  selectCollaboratorByUserId,
  selectCollaborators,
} from 'store/collaborators/collaborators.selectors';
import { selectAssignmentById } from 'store/assignments/assignments.selectors';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { AssignmentModel } from 'shared/src/assignment.schema';
import { IFormSelect } from 'domain/devices';
import FormProviderDrawer from 'providers/formProviderDrawer';
import { useTranslation } from 'react-i18next';

interface IAssignmentsForm {
  details: IFormSelect;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
  onSubmit: (data: AssignmentModel) => Promise<void>;
}

const AssignmentsForm: FC<IAssignmentsForm> = ({ details, onSubmit }) => {
  const collaborators = useSelector(selectCollaborators);
  const [filteredCollaborators, setFilteredCollaborators] = useState<
    { label: string }[]
  >([]);
  const currentDate = new Date();
  const inputVariantStyle = 'outlined';

  let methods: any;
  if (details.method === 'POST') {
    methods = useForm({
      defaultValues: {
        user_id: '',
        device_id: details.formWithData,
        assignment_start_date: currentDate.toISOString().split('T')[0],
        assignment_end_date: '',
        description: '',
      },
      reValidateMode: 'onChange',
    });
  }

  if (details.method === 'PATCH' || details.method === 'DELETE') {
    const selectedAssignment = useSelector((state) =>
      selectAssignmentById(state, Number(details.formWithData))
    );

    const collaborator: CollaboratorModel = useSelector((state) =>
      selectCollaboratorByUserId(state, selectedAssignment?.user_id)
    );

    methods = useForm({
      defaultValues: {
        id: selectedAssignment.id,
        user_id: selectedAssignment.user_id,
        email: collaborator.email,
        device_id: selectedAssignment.device_id,
        assignment_start_date: selectedAssignment?.assignment_start_date,
        assignment_end_date:
          selectedAssignment?.assignment_end_date ||
          currentDate.toISOString().split('T')[0],
        description: selectedAssignment?.description,
      },
      reValidateMode: 'onChange',
    });
  }

  const { handleSubmit, control } = methods;

  const handleInputChangeCollaborator = async (_: unknown, values: string) => {
    const collaborator = collaborators.find(
      (collaborator: CollaboratorModel) => collaborator.email === values
    );

    methods.setValue('user_id', collaborator.id);
  };

  useEffect(() => {
    const formatData = collaborators.map((collaborator: { email: string }) => {
      return {
        label: collaborator.email,
      };
    });

    setFilteredCollaborators(formatData);
  }, []);

  const { t } = useTranslation();

  return (
    <FormProviderDrawer
      methods={{ ...methods }}
      onSubmit={handleSubmit(onSubmit)}
    >
      {details.method === 'POST' && (
        <Controller
          name="user_id"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              disablePortal
              options={filteredCollaborators}
              onInputChange={handleInputChangeCollaborator}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required
                  variant={inputVariantStyle}
                  label={t('label_collaborator')}
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
          rules={{ required: 'collaborators_id required' }}
        />
      )}

      {details.method === 'DELETE' && (
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              required
              disabled={details.method === 'DELETE'}
              variant={inputVariantStyle}
              label={t('label_collaborator')}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: 'collaborators_id required' }}
        />
      )}

      <Controller
        name="assignment_start_date"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            required
            variant={inputVariantStyle}
            type="date"
            disabled={details.method === 'DELETE'}
            label={t('label_assignmentStartDate')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'assignment_start_date required' }}
      />

      <Controller
        name="assignment_end_date"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            type="date"
            required={details.method === 'DELETE'}
            label={t('label_assignmentEndDate')}
            InputLabelProps={{ shrink: true }}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_description')}
            InputLabelProps={{ shrink: true }}
            value={value}
            multiline
            rows={4}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </FormProviderDrawer>
  );
};

export default AssignmentsForm;
