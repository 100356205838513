import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRow, TableCell, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Label } from 'components/_Input';
import { MYDeviceModelWithAssign } from 'domain/devices';
import { TableRowDetails } from 'components/_Table';

export interface DeviceRowProps {
  row: MYDeviceModelWithAssign;
}

const DeviceRow: FC<DeviceRowProps> = ({ row }) => {
  const { device, assignment } = row;
  const { id, details, agency, serialnumber } = device;
  const { assignment_end_date, assignment_start_date } = assignment;
  const [openDetails, setOpenDetails] = useState(false);

  const assignmentStatus =
    assignment_end_date === '' ||
    (assignment_end_date != '' &&
      new Date(assignment_end_date) >
        new Date(new Date().toISOString().split('T')[0]));

  const { t } = useTranslation();

  return (
    <>
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
      >
        <TableCell align="left">{agency}</TableCell>

        <TableCell align="left">{details.name}</TableCell>

        <TableCell align="left">{serialnumber || 'N/A'}</TableCell>

        <TableCell align="left">
          <Label status={assignmentStatus ? 'NOT_ASSIGN' : 'ASSIGN'}>
            {assignmentStatus ? t('value_ACTIVE') : t('value_NOT_ACTIVE')}
          </Label>
        </TableCell>

        <TableCell align="left">
          {assignment_start_date != '' ? assignment_start_date : 'N/A'}
        </TableCell>
        <TableCell align="left">
          {assignment_end_date != '' ? assignment_end_date : 'N/A'}
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRowDetails
        details={details}
        openDetails={openDetails}
      />
    </>
  );
};
export default DeviceRow;
