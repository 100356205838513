import { useSelector } from 'react-redux';
import { HomeMenu } from '../../components/sections/dashboard/homePage/homePageMenu';
import { selectRole } from 'store/role/role.selectors';

const DashboardPage = () => {
  const role: string = useSelector(selectRole);
  return <HomeMenu role={role} />;
};

export default DashboardPage;
