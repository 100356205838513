import {
  CollaboratorModel,
  CollaboratorSchema,
} from 'shared/src/collaborator.schema';
import { config } from '../config';

const route = 'collaborators';
// GET all collaborators
export const GET_COLLABORATORS = async (token: string) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
};

// GET collaborator by ID
export const GET_COLLABORATORS_BY_ID = async (token: string, id: number) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

// POST create collaborator
export const POST_CREATE_COLLABORATOR = async (
  token: string,
  collaborator: CollaboratorSchema
) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(collaborator),
  });
  const data = await response.json();
  return data;
};

// PATCH update collaborator by ID
export const PATCH_UPDATE_COLLABORATOR = async (
  token: string,
  user: CollaboratorModel
) => {
  const response = await fetch(`${config.serverDomain}/${route}/${user.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  return data;
};

// DELETE remove a collaborator by ID
export const DELETE_REMOVE_COLLABORATOR = async (token: string, id: number) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};
