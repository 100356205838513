import { createAsyncThunk } from '@reduxjs/toolkit';
import AppThunkApiConfig from 'store/thunk-config-api';
import { PATCH_UPDATE_COLLABORATOR } from '../../../api/collaborator';
import { POST_ASSIGN_ROLE_TO_USER } from '../../../api/user';
import { CollaboratorModel } from 'shared/src/collaborator.schema';

interface rejectValueError {
  msg: string;
}

const updateCollaborators = createAsyncThunk<
  CollaboratorModel,
  { userRole?: string; token: string; body: CollaboratorModel },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>(
  'collaborators/updateCollaborators',
  async ({ userRole, token, body }, { rejectWithValue }) => {
    try {
      if (userRole === 'Admin') {
        await POST_ASSIGN_ROLE_TO_USER(token, body.auth0Id, body.role);
      }
      const collaborators = await PATCH_UPDATE_COLLABORATOR(token, body);
      return collaborators;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error updateCollaborators',
      });
    }
  }
);

export default updateCollaborators;
