import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Box, MenuItem, Autocomplete } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { CatalogueDeviceModel } from 'shared/src/catalogue.schema';
import { selectCatalogue } from 'store/catalogue/catalogue.selectors';
import { selectDeviceById } from 'store/devices/devices.selectors';
import FormProviderDrawer from 'providers/formProviderDrawer';
import { useTranslation } from 'react-i18next';

interface IDeviceForm {
  details: any;
  onSubmit: any;
}

const DeviceForm = ({ details, onSubmit }: IDeviceForm) => {
  const catalogue: CatalogueDeviceModel[] = useSelector(selectCatalogue);

  const selectedDevice = useSelector((state) =>
    selectDeviceById(state, details.formWithData)
  );

  const inputVariantStyle = 'outlined';
  let methods: any;

  if (details.method === 'POST') {
    methods = useForm({
      defaultValues: {
        agency: 'Montreal',
        model: 'Model',
        status: 'ORDERED',
        serialnumber: '',
        purchase_date: '',
        received_date: '',
        exit_date: '',
        name: '',
        price: 0,
        display: 0,
        storage: 0,
        cpu: '',
        ram: 0,
        keyboard: 'QWERTY',
        description: '',
      },
      reValidateMode: 'onChange',
    });
  }

  if (details.method === 'PATCH') {
    methods = useForm({
      defaultValues: {
        id: selectedDevice.id,
        agency: selectedDevice.agency,
        model: selectedDevice.model,
        status: selectedDevice.status,
        serialnumber: selectedDevice.serialnumber,
        purchase_date: selectedDevice.purchase_date,
        received_date: selectedDevice.received_date,
        exit_date: selectedDevice.exit_date,
        detailsId: selectedDevice.details.id,
        name: selectedDevice.details.name,
        price: Number(selectedDevice.details.price),
        display: Number(selectedDevice.details.display),
        storage: selectedDevice.details.storage,
        cpu: selectedDevice.details.cpu,
        ram: selectedDevice.details.ram,
        keyboard: selectedDevice.details.keyboard,
        description: selectedDevice.details.description,
      },
      reValidateMode: 'onChange',
    });
  }

  const { handleSubmit, control } = methods;

  const handleInputChange = async (
    _: unknown,
    value: { id: number; label: string } | null
  ) => {
    if (catalogue.length > 0 && value) {
      const device = catalogue.find(
        (item: CatalogueDeviceModel) => item.id === value.id
      );
      if (device) {
        methods.setValue('name', device.details.name);
        methods.setValue('model', device.model);
        methods.setValue('price', device.details.price);
        methods.setValue('display', device.details.display);
        methods.setValue('storage', device.details.storage);
        methods.setValue('cpu', device.details.cpu);
        methods.setValue('ram', device.details.ram);
        methods.setValue('keyboard', device.details.keyboard);
      }
    }
  };

  const options = catalogue
    .filter((device: CatalogueDeviceModel) => device.status === 'AVAILABLE')
    .map((device: CatalogueDeviceModel) => {
      return {
        label: device.details.name,
        id: device.id,
      };
    });
  const { t } = useTranslation();

  return (
    <FormProviderDrawer
      methods={{ ...methods }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="agency"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            select
            fullWidth
            variant={inputVariantStyle}
            label={t('label_agency')}
            value={value}
            onChange={onChange}
          >
            <MenuItem value={'Montreal'}>Montreal</MenuItem>
            <MenuItem value={'Toronto'}>Toronto</MenuItem>
          </TextField>
        )}
        rules={{ required: 'ram required' }}
      />

      {catalogue.length > 0 && details.method === 'POST' && (
        <Autocomplete
          disablePortal
          options={options}
          onChange={(e, value) => handleInputChange(e, value)}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              key={option.id}
            >
              {option.label}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label={t('label_selectDevice')}
              variant={inputVariantStyle}
            />
          )}
        />
      )}

      <Controller
        name="status"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            select
            fullWidth
            variant={inputVariantStyle}
            label={t('label_status')}
            value={value}
            onChange={onChange}
          >
            <MenuItem value={'ORDERED'}>{t('value_ORDERED')}</MenuItem>
            <MenuItem value={'RECEIVED'}>{t('value_RECEIVED')}</MenuItem>
            <MenuItem value={'OUT_OF_STOCK'}>
              {t('value_OUT_OF_STOCK')}
            </MenuItem>
          </TextField>
        )}
        rules={{ required: 'ram required' }}
      />

      <Controller
        name="purchase_date"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            type="date"
            label={t('label_purchaseDate')}
            InputLabelProps={{ shrink: true }}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'purchase date required' }}
      />

      {(methods.watch('status', 'ORDERED') === 'RECEIVED' ||
        methods.getValues().status === 'RECEIVED') && (
        <Controller
          name="received_date"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              variant={inputVariantStyle}
              type="date"
              label={t('label_receivedDate')}
              InputLabelProps={{ shrink: true }}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{
            required:
              methods.watch('status', 'ORDERED') != 'ORDERED' &&
              'received date required',
          }}
        />
      )}

      {(methods.watch('status', 'ORDERED') === 'OUT_OF_STOCK' ||
        methods.getValues().status === 'OUT_OF_STOCK') && (
        <Controller
          name="exit_date"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              variant={inputVariantStyle}
              type="date"
              label={t('label_exitDate')}
              InputLabelProps={{ shrink: true }}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{
            required:
              methods.watch('status', 'ORDERED') != 'ORDERED' &&
              'exit date required',
          }}
        />
      )}

      <Controller
        name="serialnumber"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            disabled={methods.getValues().status === 'ORDERED'}
            variant={inputVariantStyle}
            label={t('label_serialNumber')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{
          required:
            methods.watch('status', 'ORDERED') != 'ORDERED' &&
            'SerialNumber required',
        }}
      />

      <Controller
        name="model"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_model')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Name required' }}
      />

      <Controller
        name="price"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_price')}
            type="number"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'price required' }}
      />

      <Controller
        name="display"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_display')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'display required' }}
      />

      <Controller
        name="storage"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_storage')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'storage required' }}
      />

      <Controller
        name="cpu"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_cpu')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'cpu required' }}
      />

      <Controller
        name="ram"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            type="number"
            label={t('label_ram')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'ram required' }}
      />

      <Controller
        name="keyboard"
        control={control}
        defaultValue="qwerty"
        render={({ field: { onChange, value } }) => (
          <TextField
            select
            fullWidth
            variant={inputVariantStyle}
            label={t('label_keyboard')}
            value={value}
            onChange={onChange}
          >
            <MenuItem value={'QWERTY'}>QWERTY</MenuItem>
            <MenuItem value={'AZERTY'}>AZERTY</MenuItem>
          </TextField>
        )}
      />

      <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            label={t('label_description')}
            variant={inputVariantStyle}
            value={value}
            onChange={onChange}
            multiline
            rows={4}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </FormProviderDrawer>
  );
};

export default DeviceForm;
