import { useSelector } from 'react-redux';
import { AssignmentModel } from 'shared/src/assignment.schema';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { DeviceModel } from 'shared/src/device.schema';
import { selectAssignments } from 'store/assignments/assignments.selectors';
import { selectCollaborators } from 'store/collaborators/collaborators.selectors';
import { selectFilteredDevices } from 'store/devices/devices.selectors';

export const useGetDevices = (keyword: string): any => {
  const devices: DeviceModel[] = useSelector((state) =>
    selectFilteredDevices(state, keyword)
  );
  const assignments = useSelector(selectAssignments);
  const collaborators = useSelector(selectCollaborators);

  if (devices.length > 0) {
    return devices.map((device: DeviceModel) => {
      const deviceAssignments = assignments.filter(
        (assignment: AssignmentModel) => device.id === assignment.device_id
      );

      const lastDeviceAssignment = deviceAssignments.find(
        (assignment: AssignmentModel) =>
          assignment.assignment_end_date === '' ||
          (assignment.assignment_end_date != '' &&
            new Date(assignment.assignment_end_date) >
              new Date(new Date().toISOString().split('T')[0]))
      );

      if (lastDeviceAssignment) {
        const collaboratorById = collaborators.find(
          (collaborator: CollaboratorModel) =>
            collaborator.id === lastDeviceAssignment.user_id
        );

        return {
          ...device,
          assignment: lastDeviceAssignment,
          collaborator: collaboratorById,
        };
      }

      return {
        ...device,
        assignment: undefined,
        collaborator: undefined,
      };
    });
  }
  return devices;
};
