import {
  Typography,
  Button as ButtonMui,
  ButtonPropsColorOverrides,
} from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { OverridableStringUnion } from '@mui/types';
import { FC } from 'react';

interface IButtonCustomProps {
  eventOnclick?: () => void;
  children: React.ReactNode;
  icon?: boolean;
  type?: 'button' | 'submit' | 'reset';
  color?: OverridableStringUnion<
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning',
    ButtonPropsColorOverrides
  >;
}

const Button: FC<IButtonCustomProps> = (props) => {
  const { icon, eventOnclick, children, color } = props;

  return (
    <ButtonMui
      variant="contained"
      onClick={eventOnclick}
      color={color || 'primary'}
      sx={{
        borderRadius: '20px',
        textTransform: 'capitalize',
        fontSize: '14px',
        fontWeight: '600',
        padding: '8px 16px',
      }}
    >
      {icon && (
        <ControlPointIcon
          sx={{
            marginRight: '8px',
          }}
        />
      )}
      <Typography>{children}</Typography>
    </ButtonMui>
  );
};

export default Button;
