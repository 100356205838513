import { TextField, MenuItem, FormHelperText } from '@mui/material';
import FormProviderDrawer from 'providers/formProviderDrawer';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { CatalogueDeviceModel } from 'shared/src/catalogue.schema';
import { useTranslation } from 'react-i18next';

interface ICatalogForm {
  dataForm: CatalogueDeviceModel | null;
  onSubmit: (data: any) => void;
}

const CatalogForm: FC<ICatalogForm> = ({ dataForm, onSubmit }) => {
  const methods = useForm({
    defaultValues: {
      id: dataForm?.id || '',
      detailsId: dataForm?.details.id || undefined,
      model: dataForm?.model || '',
      status: dataForm?.status || '',
      name: dataForm?.details?.name || '',
      price: dataForm?.details?.price ? Number(dataForm.details.price) : 0,
      picture: dataForm?.details?.picture || '',
      display: Number(dataForm?.details?.display) || 13,
      storage: dataForm?.details?.storage || 128,
      cpu: dataForm?.details?.cpu || 'i3',
      ram: dataForm?.details?.ram || 16,
      keyboard: dataForm?.details?.keyboard || 'QWERTY',
      description: dataForm?.details?.description || '',
    },
    reValidateMode: 'onChange',
  });

  const { handleSubmit, control } = methods;

  const { t } = useTranslation();
  const inputVariantStyle = 'outlined';

  return (
    <FormProviderDrawer
      methods={{ ...methods }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_name')}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Name required' }}
      />

      <Controller
        name="model"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              variant={inputVariantStyle}
              id="model"
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('label_model')}
              required
            >
              <MenuItem value={'LAPTOP'}>{t('value_LAPTOP')}</MenuItem>
              <MenuItem value={'TABLET'}>{t('value_TABLET')}</MenuItem>
            </TextField>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        rules={{ required: 'model required' }}
      />

      <Controller
        name="status"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              variant={inputVariantStyle}
              id="status"
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('label_status')}
              required
            >
              <MenuItem value={'AVAILABLE'}>{t('value_AVAILABLE')}</MenuItem>
              <MenuItem value={'UNAVAILABLE'}>
                {t('value_UNAVAILABLE')}
              </MenuItem>
            </TextField>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        rules={{ required: 'status required' }}
      />

      <Controller
        name="price"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            variant={inputVariantStyle}
            label={t('label_price')}
            type="number"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'price required' }}
      />

      <Controller
        name="display"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              variant={inputVariantStyle}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('label_display')}
              required
            >
              <MenuItem value={13}>13 {'"'}</MenuItem>
              <MenuItem value={14}>14 {'"'}</MenuItem>
              <MenuItem value={15}>15 {'"'}</MenuItem>
              <MenuItem value={17}>17 {'"'}</MenuItem>
            </TextField>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        rules={{ required: 'display required' }}
      />

      <Controller
        name="storage"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              variant={inputVariantStyle}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('label_storage')}
              required
            >
              <MenuItem value={128}>128 GB</MenuItem>
              <MenuItem value={256}>256 GB</MenuItem>
              <MenuItem value={512}>512 GB</MenuItem>
              <MenuItem value={1024}>1 TB</MenuItem>
            </TextField>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        rules={{ required: 'storage required' }}
      />

      <Controller
        name="cpu"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              variant={inputVariantStyle}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('label_cpu')}
              required
            >
              <MenuItem value="i3">i3 </MenuItem>
              <MenuItem value="i5">i5 </MenuItem>
              <MenuItem value="i7">i7</MenuItem>
            </TextField>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        rules={{ required: 'cpu required' }}
      />

      <Controller
        name="ram"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              variant={inputVariantStyle}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('label_ram')}
              required
            >
              <MenuItem value={8}>8 GB </MenuItem>
              <MenuItem value={16}>16 GB </MenuItem>
              <MenuItem value={32}>32 GB</MenuItem>
            </TextField>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        rules={{ required: 'ram required' }}
      />

      <Controller
        name="keyboard"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <TextField
              select
              variant={inputVariantStyle}
              value={value}
              onChange={onChange}
              error={!!error}
              label={t('label_keyboard')}
              required
            >
              <MenuItem value={'QWERTY'}>QWERTY</MenuItem>
              <MenuItem value={'AZERTY'}>AZERTY</MenuItem>
            </TextField>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </>
        )}
        rules={{ required: 'keyboard required' }}
      />

      <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            fullWidth
            label={t('label_description')}
            variant={inputVariantStyle}
            value={value}
            onChange={onChange}
            multiline
            rows={4}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    </FormProviderDrawer>
  );
};

export default CatalogForm;
