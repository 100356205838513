import { createAsyncThunk } from '@reduxjs/toolkit';

const retrieveRole = createAsyncThunk(
  'user/retrieveRole',
  (role: string | null, { rejectWithValue }) => {
    try {
      return role;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error retrieveRole',
      });
    }
  }
);

export default retrieveRole;
