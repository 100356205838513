import { createAsyncThunk } from '@reduxjs/toolkit';
import { GET_ASSIGNMENT } from '../../../api/assignment';

const retrieveAssignments = createAsyncThunk(
  'assignments/retrieveAssignments',
  async (token: string, { rejectWithValue }) => {
    try {
      const assignments = await GET_ASSIGNMENT(token);
      return assignments.result.data;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error retrieveAssignments',
      });
    }
  }
);

export default retrieveAssignments;
