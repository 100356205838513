import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeviceModel } from 'shared/src/device.schema';
import AppThunkApiConfig from 'store/thunk-config-api';
import { PATCH_UPDATE_DEVICE } from '../../../api/device';
import { PartialDeep } from 'utils/types';

interface rejectValueError {
  msg: string;
}

const updateDevices = createAsyncThunk<
  PartialDeep<DeviceModel>,
  { token: string; body: PartialDeep<DeviceModel> },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>('devices/updateDevices', async ({ token, body }, { rejectWithValue }) => {
  try {
    const device = await PATCH_UPDATE_DEVICE(token, body);
    return device;
  } catch (e) {
    return rejectWithValue({
      msg: 'Error updateDevices',
    });
  }
});

export default updateDevices;
