import { useAuth0 } from '@auth0/auth0-react';
import {
  TextField,
  Autocomplete,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  FormControl,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { GET_USER_BY_EMAIL } from 'api/user';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import FormProviderDrawer from 'providers/formProviderDrawer';
import { useTranslation } from 'react-i18next';

interface ICollaboratorForm {
  userRole: string;
  dataForm: CollaboratorModel | undefined;
  method: undefined | 'POST' | 'DELETE' | 'PATCH';
  onSubmit: (data: CollaboratorModel) => Promise<void>;
  listUsers: {
    label: string;
  }[];
  collaborators: CollaboratorModel[];
}

const CollaboratorsForm: FC<ICollaboratorForm> = ({
  userRole,
  dataForm,
  method,
  onSubmit,
  listUsers,
  collaborators,
}) => {
  const [filteredUsers, setFilteredUsers] = useState<{ label: string }[]>([]);
  const initDate = new Date();
  const { getAccessTokenSilently } = useAuth0();
  const inputVariantStyle = 'outlined';
  let methods: any;

  if (method === 'POST') {
    methods = useForm({
      defaultValues: {
        agency: '',
        firstName: '',
        lastName: '',
        email: '',
        auth0Id: '',
        onboarding_date:
          dataForm?.onboarding_date ?? initDate.toISOString().split('T')[0],
        offboarding_date: '',
        role: 'User',
        picture: '',
      },
      reValidateMode: 'onChange',
    });
  }

  if (method === 'PATCH' || method === 'DELETE') {
    methods = useForm({
      defaultValues: {
        id: dataForm?.id,
        agency: dataForm?.agency ?? '',
        firstName: dataForm?.firstName ?? '',
        lastName: dataForm?.lastName ?? '',
        email: dataForm?.email ?? '',
        auth0Id: dataForm?.auth0Id ?? '',
        onboarding_date:
          dataForm?.onboarding_date ?? initDate.toISOString().split('T')[0],
        offboarding_date:
          method === 'DELETE'
            ? initDate.toISOString().split('T')[0]
            : dataForm?.offboarding_date ?? '',
        role: dataForm?.role ?? '',
      },
      reValidateMode: 'onChange',
    });
  }

  useEffect(() => {
    const filteredUsers = listUsers.filter((email) => {
      const collaboratorFound = collaborators.some(
        (user) => user.email === email.label
      );
      return !collaboratorFound;
    });

    setFilteredUsers(filteredUsers);
  }, []);

  const { handleSubmit, control } = methods;

  const handleInputChange = async (_: unknown, values: string) => {
    const token = await getAccessTokenSilently();
    const getUserId = async () => {
      return await GET_USER_BY_EMAIL(token, values);
    };

    const checkIfUserExist = await getUserId();
    if (checkIfUserExist.result.status === 'dataFound') {
      methods.setValue('email', values);
      methods.setValue('firstName', checkIfUserExist.result.data[0].given_name);
      methods.setValue('lastName', checkIfUserExist.result.data[0].family_name);
      methods.setValue('auth0Id', checkIfUserExist.result.data[0].user_id);
      methods.setValue('picture', checkIfUserExist.result.data[0].picture);
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <FormProviderDrawer
        methods={{ ...methods }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {method === 'POST' && filteredUsers.length > 0 && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={filteredUsers}
            onInputChange={handleInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={t('label_selectUser')}
              />
            )}
          />
        )}
        <Controller
          name="role"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl>
              {method === 'DELETE' ? (
                <TextField
                  fullWidth
                  disabled
                  variant={inputVariantStyle}
                  label="role"
                  value={value}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              ) : (
                <>
                  {userRole === 'Admin' && (
                    <>
                      <FormLabel id="role">Role</FormLabel>
                      <RadioGroup
                        row
                        name="role"
                        value={value}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value="User"
                          control={<Radio />}
                          label={t('value_user')}
                        />
                        <FormControlLabel
                          value="Manager"
                          control={<Radio />}
                          label={t('value_manager')}
                        />
                        <FormControlLabel
                          value="Admin"
                          control={<Radio />}
                          label={t('value_admin')}
                        />
                      </RadioGroup>
                    </>
                  )}
                </>
              )}
            </FormControl>
          )}
          rules={{ required: 'role required' }}
        />

        <Controller
          name="agency"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <TextField
                select
                disabled={method === 'DELETE'}
                variant={inputVariantStyle}
                value={value}
                onChange={onChange}
                error={!!error}
                label={t('label_agency')}
                required
              >
                <MenuItem value={'Montreal'}>Montreal</MenuItem>
                <MenuItem value={'Toronto'}>Toronto</MenuItem>
              </TextField>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </>
          )}
          rules={{ required: 'agency required' }}
        />
        <Controller
          name="firstName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              disabled={method === 'DELETE'}
              required
              variant={inputVariantStyle}
              label={t('label_firstName')}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: 'firstName required' }}
        />

        <Controller
          name="lastName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              disabled={method === 'DELETE'}
              required
              variant={inputVariantStyle}
              label={t('label_lastName')}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: 'lastName required' }}
        />

        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              disabled={method === 'DELETE'}
              required
              id="standard-basic"
              variant={inputVariantStyle}
              label="Email"
              type={'email'}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: 'email required' }}
        />

        <Controller
          name="onboarding_date"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              fullWidth
              disabled={method === 'DELETE'}
              required
              type="date"
              variant={inputVariantStyle}
              label={t('label_onboardingDate')}
              InputLabelProps={{ shrink: true }}
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: 'onboarding_date required' }}
        />

        {(method === 'PATCH' || method === 'DELETE') && (
          <Controller
            name="offboarding_date"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                disabled={method === 'DELETE'}
                variant={inputVariantStyle}
                type="date"
                label={t('label_offboardingDate')}
                InputLabelProps={{ shrink: true }}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        )}
      </FormProviderDrawer>
    </>
  );
};

export default CollaboratorsForm;
