import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, green } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded';
import MoodBadRoundedIcon from '@mui/icons-material/MoodBadRounded';
import { StatusDevices } from '../enum/enumDevice';
import DetailsList from './DetailsList';
import { Button, Tooltip } from '@mui/material';
import { DeviceDetailsSchema } from 'shared/src/device.schema';
import { useState } from 'react';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface ICardDevice {
  device: {
    id: number;
    status: string;
    model: string;
    details: DeviceDetailsSchema;
  };
}

const CardDevice = ({ device }: ICardDevice) => {
  const { model, status, id, details } = device;
  const { name, description } = details;
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <Card
      key={id}
      sx={{ maxWidth: 345 }}
    >
      <CardHeader
        avatar={
          status === StatusDevices.AVAILABLE ? (
            <Tooltip
              title={status}
              placement="top-start"
            >
              <CheckCircleOutlineRoundedIcon
                sx={{
                  color: green[300],
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={status}
              placement="top-start"
            >
              <DoDisturbAltRoundedIcon
                sx={{
                  color: red[300],
                }}
              />
            </Tooltip>
          )
        }
        title={name}
        subheader={model}
      />
      <CardMedia
        component="img"
        height="194"
        image={`/assets/${name}.png`}
        alt="device picture"
      />
      <CardContent>
        <Typography
          variant="body2"
          color="text.secondary"
        >
          {description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <CardActions>
          {status != StatusDevices.UNAVAILABLE ? (
            <Button
              size="small"
              onClick={() => alert(`order this device ${name}`)}
            >
              Order
            </Button>
          ) : (
            <Tooltip
              title="SORRY IS NOT READY"
              placement="top-start"
            >
              <MoodBadRoundedIcon />
            </Tooltip>
          )}
        </CardActions>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>
          <DetailsList deviceDetails={details} />
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default CardDevice;
