import { FC, SetStateAction, useState } from 'react';
import { Card, Table, TableBody, TableContainer } from '@mui/material';

import { DeviceRow } from '../deviceRow';
import { TableHeadRow } from 'components/tables/table/tableHeadRow';
import { DeviceModelWithAssign } from 'domain/devices';
import { ILegendTableHead } from 'components/_Table/types';
interface DeviceTableProps {
  devices: DeviceModelWithAssign[];
  legend: ILegendTableHead[];
  toggleDrawer: (state: boolean, formName: string, row: any) => void;
  toggleDrawerAssignment: (state: boolean, formName: string, row: any) => void;
}

const DeviceTable: FC<DeviceTableProps> = ({
  devices,
  legend,
  toggleDrawerAssignment,
  toggleDrawer,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);

  const handleRequestSort = (_: unknown, property: SetStateAction<null>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHeadRow
              order={order}
              orderBy={orderBy}
              headLabel={legend}
              rowCount={devices.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {devices.map((row) => (
                <DeviceRow
                  key={row.id}
                  toggleDrawer={toggleDrawer}
                  toggleDrawerAssignment={toggleDrawerAssignment}
                  row={row}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};
export default DeviceTable;
