import { Box, IconButton } from '@mui/material';
import { LanguagePopover } from './languagePopover';
import { AccountPopover } from './accountPopover';
import { StyledContainerHeader } from './styles';
import { Roles } from '../../../components/enum/enumRoles';
import { useAuth0 } from '@auth0/auth0-react';
import useResponsive from '../../../hooks/useResponsive';

export const Header = (props: any) => {
  const { onOpenNav } = props;
  const { user } = useAuth0();
  const isDesktop = useResponsive('up', 'lg');
  const checkIfAuthorized = user?.detail_roles.find(
    (role: Roles) => role === Roles.ADMIN || role === Roles.MANAGER
  );

  return (
    <StyledContainerHeader>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: '24px',
        }}
      >
        {!isDesktop && checkIfAuthorized && (
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
              position: 'absolute',
              left: 0,
            }}
          >
            <Box
              component="img"
              alt={'logo Zenika'}
              src={'/assets/icons/navbar/ic_burger_menu.svg'}
              sx={{ width: '32px' }}
            />
          </IconButton>
        )}
        <LanguagePopover />
        <AccountPopover />
      </Box>
    </StyledContainerHeader>
  );
};
