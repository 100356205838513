import { Stack, Typography, Box } from '@mui/material';
import { FC } from 'react';
import { Roles } from 'components/enum/enumRoles';
import SvgColor from 'components/svgColor';
import { Button } from 'components/_Input';

type TableHeaderProps = {
  icon: string;
  title: string;
  role?: string;
  CTA?: {
    text: string;
    action: () => void;
  };
};

const TableHeader: FC<TableHeaderProps> = (props) => {
  const { icon, title, CTA, role } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        paddingTop: '16px ',
      }}
      mb={5}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgColor
          src={`/assets/icons/${icon}.svg`}
          sx={{ width: 40, height: 40 }}
        />
        <Typography
          variant="body2"
          sx={{
            marginLeft: '24px',
            fontSize: '40px',
            color: 'text.primary',
          }}
        >
          {title}
        </Typography>
      </Box>
      {CTA && role != Roles.USER && (
        <Button eventOnclick={CTA.action}>{CTA.text}</Button>
      )}
    </Stack>
  );
};
export default TableHeader;
