import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton, Typography } from '@mui/material';

export const StyledNavItem = styled((props) => <ListItemButton {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 160,
    width: 160,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    alignItems: 'center',
    justifyContent: 'center',
  })
);

export const StyledNavItemText = styled(Typography)(() => ({
  color: 'rgb(33, 43, 54)',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  marginTop: '0.5rem',
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 48,
  height: 48,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
