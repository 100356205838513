import { config } from '../config';

const route = 'users';
// GET all users
export const GET_USERS = async (token: string) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  return data;
};

// GET user by ID
export const GET_USER_BY_ID = async (token: string, id: number) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

// GET user by ID
export const GET_USER_BY_EMAIL = async (token: string, email: string) => {
  const response = await fetch(
    `${config.serverDomain}/${route}/${email}/user`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const data = await response.json();
  return data;
};

// GET user'roles by ID
export const GET_USER_ROLES_BY_ID = async (token: string, id: number) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}/roles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

// POST create user
export const POST_CREATE_USER = async (token: string, user: any) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  return data;
};

// PATCH update user by ID
export const PATCH_UPDATE_USER = async (
  token: string,
  id: number,
  user: Partial<unknown>
) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  });
  const data = await response.json();
  return data;
};

// POST assign role to user
export const POST_ASSIGN_ROLE_TO_USER = async (
  token: string,
  id: string,
  role: string
) => {
  const dataRole = { roles: [role] };

  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(dataRole),
  });
  const data = await response.json();
  return data;
};

// POST remove role from user
export const DELETE_ASSIGNED_ROLES_FROM_USER = async (
  token: string,
  id: string
) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}/roles`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};
