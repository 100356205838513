import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemText, ListItemButton } from '@mui/material';

export const StyledListItemButton = styled((props) => (
  <ListItemButton {...props}>{props.children}</ListItemButton>
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}));

export const StyledNavItemText = styled((props) => <ListItemText {...props} />)(
  () => ({
    color: 'rgb(33, 43, 54)',
  })
);

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
