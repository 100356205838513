import { createSlice } from '@reduxjs/toolkit';
import retrieveRole from './use-case/retrieveRole';

const initialState = '';

const { reducer } = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveRole.fulfilled, (state, { payload }) => {
      return payload;
    });
  },
});

export default reducer;
