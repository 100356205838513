import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NotAuthorizedPage from '../../pages/notAuthorizedPage';
import { useAuth0 } from '@auth0/auth0-react';
import useAuthorized from 'hooks/useAuthorized';

interface IProtectedRoute {
  children: React.ReactNode;
  role: string;
}
export const ProtectedRoute: FC<IProtectedRoute> = ({ children, role }) => {
  const { isAuthorizedUser } = useAuthorized();
  const [isAuthorization, setIsAuthorization] = useState<boolean>(false);
  const { user, isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const authorizedUser = isAuthorizedUser(user, role);

  useEffect(() => {
    if (typeof window === 'undefined' || !user) return;

    setIsAuthorization(true);
  }, [user]);

  if (!isLoading && !isAuthenticated) {
    navigate('/');
  }

  if (!authorizedUser) return <NotAuthorizedPage />;

  return <React.Fragment>{isAuthorization && children}</React.Fragment>;
};
