import { createAsyncThunk } from '@reduxjs/toolkit';
import AppThunkApiConfig from 'store/thunk-config-api';
import { POST_CREATE_COLLABORATOR } from '../../../api/collaborator';
import { POST_ASSIGN_ROLE_TO_USER } from '../../../api/user';
import { CollaboratorSchema } from 'shared/src/collaborator.schema';

interface rejectValueError {
  msg: string;
}

const createCollaborators = createAsyncThunk<
  CollaboratorSchema,
  { token: string; body: CollaboratorSchema },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>(
  'collaborators/createCollaborators',
  async ({ token, body }, { rejectWithValue }) => {
    try {
      await POST_ASSIGN_ROLE_TO_USER(token, body.auth0Id, body.role);
      const collaborators = await POST_CREATE_COLLABORATOR(token, body);
      return collaborators;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error createCollaborators',
      });
    }
  }
);

export default createCollaborators;
