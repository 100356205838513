import { createSlice } from '@reduxjs/toolkit';
import retrieveDevices from './use-case/retrieveDevices';
import updateDevices from './use-case/updateDevices';
import createDevices from './use-case/createDevices';

const initialState = [];

const { reducer } = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveDevices.fulfilled, (state, { payload }) => {
      return [...payload];
    });
    builder.addCase(updateDevices.fulfilled, (state, { payload }) => {
      const newState = state.map((device) =>
        device.id === payload?.result?.data.id ? payload.result.data : device
      );
      return [...newState];
    });
    builder.addCase(createDevices.fulfilled, (state, { payload }) => {
      if (payload.result.status === 'writeFileSuccess') {
        return [payload.result.data, ...state];
      }
    });
  },
});

export default reducer;
