import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { Drawer } from '../../components/sections/dashboard/_common/drawer';
import { CollaboratorsTable } from '../../components/sections/dashboard/collaborators/collaboratorsTable';
import { CollaboratorsForm } from '../../components/sections/dashboard/collaborators/collaboratorsForm';
import { selectCollaborators } from 'store/collaborators/collaborators.selectors';
import updateCollaborators from 'store/collaborators/use-case/updateCollaborators';
import createCollaborators from 'store/collaborators/use-case/createCollaborators';
import ProtectedRoute from '../../components/protectedRoute';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { GET_USER_BY_EMAIL, GET_USERS } from '../../api/user';
import { applySortTableFilter } from 'utils/table_utils';
import { SearchBar } from 'components/searchBar';
import { selectRole } from 'store/role/role.selectors';
import { AppDispatch } from 'store/store';
import { Roles } from '../../components/enum/enumRoles';
import TableHeader from 'components/tables/table/tableHeader';
import { useTranslation } from 'react-i18next';
import { TableRowDataNotFound } from 'components/_Table';

const legend = [
  { id: 'picture', labelKey: 'label_picture', alignRight: false },
  { id: 'firstName', labelKey: 'label_firstName', alignRight: false },
  { id: 'lastName', labelKey: 'label_lastName', alignRight: false },
  { id: 'status', labelKey: 'label_status' },
  { id: 'details', labelKey: 'label_details' },
  { id: 'action', labelKey: 'label_action' },
];

const CollaboratorPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch<AppDispatch>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [dataForm, setDataForm] = useState<CollaboratorModel>();
  const [listUsers, setListUsers] = useState<{ label: string }[]>([]);
  const [method, setMethod] = useState<undefined | 'POST' | 'DELETE' | 'PATCH'>(
    undefined
  );
  const collaborators: CollaboratorModel[] = useSelector(selectCollaborators);
  const [dataFilter, setDataFilter] = useState<CollaboratorModel[]>([]);

  useEffect(() => {
    setDataFilter(collaborators);
  }, [collaborators]);
  const role: string = useSelector(selectRole);

  const toggleDrawer = (open: boolean, row: CollaboratorModel | undefined) => {
    if (!open) {
      setMethod(undefined);
      setDataForm(undefined);
    } else {
      setDataForm(row);
    }
    setOpenDrawer(open);
  };

  const resetState = () => {
    setOpenDrawer(false);
  };

  const onSubmit = async (data: CollaboratorModel) => {
    const token = await getAccessTokenSilently();

    const newFormData = {
      agency: data.agency,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      offboarding_date: data.offboarding_date,
      onboarding_date: data.onboarding_date,
      auth0Id: data.auth0Id,
      role: data.role,
      picture: data.picture,
    };
    const updatedData = {
      id: data.id,
      ...newFormData,
    };

    if (method === 'POST') {
      const getUserId = async () => {
        return await GET_USER_BY_EMAIL(token, data.email);
      };
      const checkIfUserExist = await getUserId();

      if (
        checkIfUserExist.result &&
        checkIfUserExist.result.status === 'dataFound'
      ) {
        newFormData.auth0Id = checkIfUserExist.result.data[0].user_id;
      }
      dispatch(
        createCollaborators({
          token,
          body: newFormData,
        })
      );
    }

    if (method === 'PATCH' || method === 'DELETE') {
      if (method === 'PATCH') {
        dispatch(
          updateCollaborators({
            userRole: role,
            token,
            body: updatedData,
          })
        );
      } else {
        const currentDate = new Date().toISOString().split('T')[0];
        dispatch(
          updateCollaborators({
            token,
            body: { ...updatedData, offboarding_date: currentDate },
          })
        );
      }
    }

    resetState();
  };

  useEffect(() => {
    (async () => {
      const token = await getAccessTokenSilently();
      const users = await GET_USERS(token);
      const auth0UsersList = users?.result?.data.map(
        (user: { email: string }) => {
          return {
            label: user.email,
          };
        }
      );

      setListUsers(auth0UsersList);
    })();
  }, []);

  const { t } = useTranslation();

  const onChangeFilter = (event: { target: { value: string } }) => {
    const value = event.target.value.toLowerCase();
    const filteredDevices = applySortTableFilter<CollaboratorModel>(
      collaborators,
      value
    );
    setDataFilter(filteredDevices);
  };

  return (
    <ProtectedRoute role={Roles.MANAGER}>
      <Container
        sx={{
          textAlign: 'initial',
        }}
      >
        <TableHeader
          icon={'navbar/ic_users'}
          title={t('label_collaborators')}
          CTA={{
            text: t('label_newCollaborator'),
            action: () => {
              setMethod('POST');
              toggleDrawer(true, undefined);
            },
          }}
        />
        <SearchBar
          placeholder={t('label_searchCollaboratorPage')}
          onChangeFilter={onChangeFilter}
        />
        {dataFilter.length > 0 ? (
          <CollaboratorsTable
            dataRows={dataFilter}
            legend={legend}
            toggleDrawer={toggleDrawer}
            setMethod={setMethod}
          />
        ) : (
          <TableRowDataNotFound legend={legend} />
        )}

        <Drawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          component={
            <CollaboratorsForm
              userRole={role}
              dataForm={dataForm}
              method={method}
              onSubmit={onSubmit}
              listUsers={listUsers}
              collaborators={collaborators}
            />
          }
        />
      </Container>
    </ProtectedRoute>
  );
};

export default CollaboratorPage;
