import './i18n';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ProfilePage from './pages/profilePage';
import DashboardPage from './pages/dashboardPage';
import CatalogPage from './pages/catalogPage';
import DevicesPage from './pages/devicesPage';
import CollaboratorsPage from './pages/collaboratorPage';
import NotFoundPage from './pages/notFoundPage';
import NotAuthorizedPage from './pages/notAuthorizedPage';
import { InitLayout } from './layouts/InitLayout';
import DeviceDetails from './pages/devicesPage/deviceHistory/DeviceDetailsHistory';
import MyDevicesPage from './pages/devicesPage/myDevicesPage';

const App = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <InitLayout />,
      children: [
        {
          path: '',
          element: <DashboardPage />,
        },
        {
          path: 'profile',
          element: <ProfilePage />,
        },
        {
          path: 'unauthorized',
          element: <NotAuthorizedPage />,
        },
      ],
    },
    {
      path: 'dashboard',
      element: <InitLayout />,
      children: [
        {
          path: '',
          element: <DashboardPage />,
        },
        {
          path: 'catalog',
          element: <CatalogPage />,
        },
        {
          path: 'devices',
          element: <DevicesPage />,
        },
        {
          path: 'devices/:id',
          element: <DeviceDetails />,
        },
        {
          path: 'my_devices',
          element: <MyDevicesPage />,
        },
        {
          path: 'collaborators',
          element: <CollaboratorsPage />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
