import { createAsyncThunk } from '@reduxjs/toolkit';

const retrieveUser = createAsyncThunk(
  'user/retrieveUser',
  (user: any, { rejectWithValue }) => {
    try {
      return user;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error retrieveUser',
      });
    }
  }
);

export default retrieveUser;
