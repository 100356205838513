import { FC, SetStateAction, useState } from 'react';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import { CollaboratorsRow } from '../collaboratorsRow';
import { IEvent } from 'components/searchBar/SearchBar';
import { TableHeadRow } from 'components/tables/table/tableHeadRow';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { ILegendTableHead } from 'components/_Table/types';

interface ICollaboratorsTable {
  dataRows: CollaboratorModel[];
  legend: ILegendTableHead[];
  toggleDrawer: (open: boolean, row: CollaboratorModel | undefined) => void;
  setMethod: (
    data: SetStateAction<'POST' | 'DELETE' | 'PATCH' | undefined>
  ) => void;
}

const CollaboratorsTable: FC<ICollaboratorsTable> = ({
  dataRows,
  legend,
  toggleDrawer,
  setMethod,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const datalist = dataRows || [];

  const handleRequestSort = (event: IEvent, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHeadRow
            order={order}
            orderBy={orderBy}
            headLabel={legend}
            rowCount={datalist.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {datalist.map((row) => (
              <CollaboratorsRow
                key={row.id}
                row={row}
                toggleDrawer={toggleDrawer}
                setMethod={setMethod}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
export default CollaboratorsTable;
