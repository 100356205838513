import { FC, SetStateAction, useState } from 'react';
import {
  TableRow,
  TableCell,
  Stack,
  Avatar,
  Popover,
  MenuItem,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { CollaboratorModel } from 'shared/src/collaborator.schema';
import { Label } from 'components/_Input';

import { useTranslation } from 'react-i18next';
import { TableRowProfile } from 'components/_Table';

interface CollaboratorsRowProps {
  row: CollaboratorModel;
  toggleDrawer: (state: boolean, row: CollaboratorModel) => void;
  setMethod: (
    method: SetStateAction<'POST' | 'DELETE' | 'PATCH' | undefined>
  ) => void;
}

const CollaboratorsRow: FC<CollaboratorsRowProps> = ({
  row,
  toggleDrawer,
  setMethod,
}) => {
  const {
    id,
    firstName,
    lastName,
    offboarding_date,
    onboarding_date,
    role,
    agency,
    picture,
  } = row;

  const profile = {
    name: `${firstName} ${lastName}`,
    onboarding_date,
    offboarding_date,
    picture,
    role,
    agency,
  };
  const currentDate = new Date().toISOString().split('T')[0];
  const [openDetails, setOpenDetails] = useState(false);
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleOpenFormCollaboratorPatch = () => {
    toggleDrawer(true, row);
    setMethod('PATCH');
    setOpen(null);
  };

  const handleOpenFormCollaboratorActivate = () => {
    const data = { ...row, offboarding_date: '' };
    toggleDrawer(true, data);
    setMethod('PATCH');
    setOpen(null);
  };

  const handleOpenFormCollaboratorRemove = () => {
    toggleDrawer(true, row);
    setMethod('DELETE');
    setOpen(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
        >
          <Stack
            direction="row"
            spacing={2}
          >
            <Avatar
              src={picture}
              sx={{
                height: 32,
                width: 32,
                maxHeight: { xs: 32, md: 32 },
                maxWidth: { xs: 32, md: 32 },
              }}
            />
          </Stack>
        </TableCell>

        <TableCell align="left">{firstName}</TableCell>
        <TableCell align="left">{lastName}</TableCell>
        <TableCell align="left">
          <Label
            status={
              offboarding_date && offboarding_date <= currentDate
                ? 'NOT_ACTIVE'
                : 'ACTIVE'
            }
          >
            {offboarding_date && offboarding_date <= currentDate
              ? t('value_NOT_ACTIVE')
              : t('value_ACTIVE')}
          </Label>
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <IconButton
            size="large"
            color="inherit"
            onClick={handleOpenMenu}
          >
            <DragIndicatorIcon></DragIndicatorIcon>
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRowProfile
        profile={profile}
        openDetails={openDetails}
      />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            minWidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {row?.offboarding_date === '' ? (
          <>
            <MenuItem onClick={handleOpenFormCollaboratorPatch}>
              {t('label_edit')}
            </MenuItem>
            <MenuItem onClick={handleOpenFormCollaboratorRemove}>
              {t('label_delete')}
            </MenuItem>
          </>
        ) : (
          <MenuItem onClick={handleOpenFormCollaboratorActivate}>
            {t('label_activate')}
          </MenuItem>
        )}
      </Popover>
    </>
  );
};
export default CollaboratorsRow;
