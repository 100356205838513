import { FC, SetStateAction, useState } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

import { DeviceHistoryRow } from '../deviceHistoryRow';
import { AssignmentModel } from 'shared/src/assignment.schema';
import { TableHeadRow } from 'components/tables/table/tableHeadRow';

const TABLE_HEAD = [
  { id: 'name', labelKey: 'label_deviceName', alignRight: false },
  { id: 'serial_number', labelKey: 'label_serialNumber', alignRight: false },
  { id: 'status', labelKey: 'label_status', alignRight: false },
  { id: 'collaborator_name', labelKey: 'label_collaboratorName' },
  { id: 'assignment_start_date', labelKey: 'label_assignmentStartDate' },
  { id: 'assignment_end_date', labelKey: 'label_assignmentEndDate' },
  { id: 'assigned_by', labelKey: 'label_assignedBy' },
  { id: 'description', labelKey: 'label_description' },
];

interface DeviceTableProps {
  assignments: AssignmentModel[];
}

const DeviceHistoryTable: FC<DeviceTableProps> = ({ assignments }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);

  const handleRequestSort = (_: unknown, property: SetStateAction<null>) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <Card>
        <TableContainer>
          <Table>
            <TableHeadRow
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={assignments.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {assignments.length > 0 ? (
                assignments.map((row: AssignmentModel) => (
                  <DeviceHistoryRow
                    key={row.id}
                    row={row}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pt: 5,
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 100,
                          width: 100,
                        }}
                        alt="Device Picture"
                        src={`/assets/empty-data.jpg`}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="body1">NO DATA</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};
export default DeviceHistoryTable;
