import {
  combineReducers,
  configureStore,
  Reducer,
  AnyAction,
  createAction,
} from '@reduxjs/toolkit';
import devicesReducer from './devices/devices.slice';
import collaboratorsReducer from './collaborators/collaborators.slice';
import catalogueReducer from './catalogue/catalogue.slice';
import assignmentsReducer from './assignments/assignments.slice';
import userReducer from './user/user.slice';
import roleReducer from './role/role.slice';

export const combinedReducer = combineReducers({
  devices: devicesReducer,
  collaborators: collaboratorsReducer,
  catalogue: catalogueReducer,
  assignments: assignmentsReducer,
  user: userReducer,
  role: roleReducer,
});

export const resetStore = createAction<void>('store/resetStore');

export type FromCombinedReducer =
  | ReturnType<typeof combinedReducer>
  | undefined;

export const rootReducer: Reducer = (
  state: FromCombinedReducer,
  action: AnyAction
) => {
  let newState = state;
  if (action.type === resetStore.type) {
    newState = action.payload as FromCombinedReducer;
  }
  return combinedReducer(newState, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
