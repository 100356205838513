import { Box } from '@mui/material';
import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/_Input';

interface IForm {
  children: React.ReactNode;
  methods: any;
  onSubmit: (data: any) => void;
}

const FormProviderDrawer: FC<IForm> = ({ children, methods, onSubmit }) => {
  const { t } = useTranslation();

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            height: '100vh',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              overflowY: 'auto',
              display: 'flex',
              flex: 'auto',
              padding: '20px',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
          <Box
            sx={{
              borderTop: '1px solid rgba(0, 0, 0, 0.12)',
              minHeight: '80px',
              maxHeight: '80px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button type="submit">{t('label_save')}</Button>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

export default FormProviderDrawer;
