import { createSlice } from '@reduxjs/toolkit';
import retrieveCatalogue from './use-case/retrieveCatalogue';
import createDeviceCatalog from './use-case/createCatalogDevice';
import updateCatalogDevice from './use-case/updateCatalogDevice';
const initialState = [];

const { reducer } = createSlice({
  name: 'catalog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrieveCatalogue.fulfilled, (state, { payload }) => {
      return [...payload];
    });
    builder.addCase(createDeviceCatalog.fulfilled, (state, { payload }) => {
      if (payload.result.status === 'writeFileSuccess') {
        return [payload.result.data, ...state];
      }
    });
    builder.addCase(updateCatalogDevice.fulfilled, (state, { payload }) => {
      const newState = state.map((device) =>
        device.id === payload?.result?.data.id ? payload.result.data : device
      );
      return [...newState];
    });
  },
});

export default reducer;
