import { useSelector } from 'react-redux';
import { Box, Container } from '@mui/material';
import { Roles } from '../../../components/enum/enumRoles';
import { DeviceHistoryTable } from 'components/sections/dashboard/devices/history/deviceHistory';
import { selectAssignmentsByDeviceId } from 'store/assignments/assignments.selectors';
import { Link, useParams } from 'react-router-dom';
import ProtectedRoute from 'components/protectedRoute';
import { AssignmentModel } from 'shared/src/assignment.schema';
import MyButton from 'components/buttons';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import TableHeader from 'components/tables/table/tableHeader';
import { useTranslation } from 'react-i18next';

const DevicesPage = () => {
  const params = useParams();

  const assignments: AssignmentModel[] = useSelector(
    selectAssignmentsByDeviceId(params.id ? Number(params.id) : undefined)
  );

  const { t } = useTranslation();

  return (
    <ProtectedRoute role={Roles.MANAGER}>
      <Container
        sx={{
          textAlign: 'initial',
        }}
      >
        <Box sx={{ mb: 1 }}>
          <Link
            to={`/dashboard/devices`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <MyButton label={t('label_backToDevice')}>
              <KeyboardReturnIcon />
            </MyButton>
          </Link>
        </Box>

        <TableHeader
          icon={'pages/ic_device_history'}
          title={t('label_deviceHistory')}
        />
        <DeviceHistoryTable assignments={assignments} />
      </Container>
    </ProtectedRoute>
  );
};

export default DevicesPage;
