import { createAsyncThunk } from '@reduxjs/toolkit';
import AppThunkApiConfig from 'store/thunk-config-api';
import { DELETE_REMOVE_COLLABORATOR } from '../../../api/collaborator';
import { DELETE_ASSIGNED_ROLES_FROM_USER } from '../../../api/user';
import { CollaboratorModel } from 'shared/src/collaborator.schema';

interface rejectValueError {
  msg: string;
}

const deleteCollaborators = createAsyncThunk<
  CollaboratorModel,
  { token: string; body: CollaboratorModel },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>(
  'collaborators/deleteCollaborators',
  async ({ token, body }, { rejectWithValue }) => {
    try {
      await DELETE_ASSIGNED_ROLES_FROM_USER(token, body.auth0Id);
      const collaborators = await DELETE_REMOVE_COLLABORATOR(token, body?.id);
      return collaborators;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error deleteCollaborators',
      });
    }
  }
);

export default deleteCollaborators;
