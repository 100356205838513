import { DeviceModel } from 'shared/src/device.schema';
import type { RootState } from '../store';
import { applySortTableFilter } from 'utils/table_utils';

export const selectDevices = (state: RootState) => state.devices;

export const selectFilteredDevices = (state: RootState, keyword: string) => {
  const filteredDevicesData = applySortTableFilter<DeviceModel>(
    state.devices,
    keyword
  );
  return filteredDevicesData;
};

export const selectDeviceById = (state: RootState, deviceId: number) => {
  return state.devices.find((device: DeviceModel) => device.id === deviceId);
};
