import { Box, Button } from '@mui/material';
import React, { FC } from 'react';

export interface IButton {
  label: string;
  children?: React.ReactNode;
}

const MyButton: FC<IButton> = ({ label, children }) => {
  return (
    <Box>
      <Button>
        {children && children}
        {label}
      </Button>
    </Box>
  );
};

export default MyButton;
