import { config } from '../config';
import { CatalogueDeviceSchema } from 'shared/src/catalogue.schema';

const route = 'catalogue';
// GET all devices in catalogue
export const GET_DEVICES_IN_CATALOGUE = async (token: string) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  return data;
};

// GET a device by ID in catalogue
export const GET_DEVICE_ID_IN_CATALOGUE = async (token: string, id: number) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

// POST create device in catalogue
export const POST_CREATE_DEVICE_IN_CATALOGUE = async (
  token: string,
  body: CatalogueDeviceSchema
) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

// PATCH update device by ID in catalogue
export const PATCH_UPDATE_DEVICE_IN_CATALOGUE = async (
  token: string,
  id: number,
  body: CatalogueDeviceSchema
) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};
