/* eslint-disable react/prop-types */
import { FC, useState } from 'react';
import {
  TableRow,
  TableCell,
  Popover,
  MenuItem,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useAuth0 } from '@auth0/auth0-react';
import { Label } from 'components/_Input';
import { GET_ASSIGNMENT_BY_ID } from 'api/assignment';
import { Link } from 'react-router-dom';
import { DeviceModelWithAssign } from 'domain/devices';
import { useTranslation } from 'react-i18next';
import { TableRowDetails } from 'components/_Table';

interface DeviceRowProps {
  row: DeviceModelWithAssign;
  toggleDrawer: (state: boolean, formName: string, row: number) => void;
  toggleDrawerAssignment: (
    state: boolean,
    formName: string,
    row?: number | null
  ) => void;
}

const DeviceRow: FC<DeviceRowProps> = ({
  row,
  toggleDrawer,
  toggleDrawerAssignment,
}) => {
  const {
    id,
    details,
    status,
    agency,
    assignment,
    serialnumber,
    collaborator,
  } = row;
  const [openDetails, setOpenDetails] = useState(false);
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleOpenFormDevice = (id: number) => {
    toggleDrawer(true, 'PATCH', id);
    setOpen(null);
  };

  const handleOpenFormCreateAssignment = (id: number) => {
    toggleDrawerAssignment(true, 'POST', id);
    setOpen(null);
  };

  const handleOpenFormDeleteAssignment = async (id: number) => {
    const accessToken = await getAccessTokenSilently();
    const assignment = await GET_ASSIGNMENT_BY_ID(accessToken, id);

    if (assignment) {
      toggleDrawerAssignment(true, 'DELETE', id);
    }
    setOpen(null);
  };

  const { t } = useTranslation();

  return (
    <>
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
      >
        <TableCell align="left">{agency}</TableCell>
        <TableCell align="left">{details.name}</TableCell>
        <TableCell align="left">{serialnumber || 'N/A'}</TableCell>
        <TableCell align="left">
          <Label status={status}>{t(`value_${status}`)}</Label>
        </TableCell>

        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left">
          <Label status={assignment ? 'NOT_ASSIGN' : 'ASSIGN'}>
            {collaborator
              ? collaborator?.firstName + ' ' + collaborator.lastName
              : t('value_NOT_ASSIGNED')}
          </Label>
        </TableCell>

        <TableCell align="left">
          <IconButton
            size="large"
            color="inherit"
            onClick={handleOpenMenu}
          >
            <DragIndicatorIcon></DragIndicatorIcon>
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRowDetails
        details={details}
        openDetails={openDetails}
      />

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            minWidth: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => handleOpenFormDevice(row.id)}>
          {t('label_edit')}
        </MenuItem>

        {!assignment && (
          <MenuItem onClick={() => handleOpenFormCreateAssignment(row.id)}>
            {t('label_assign')}
          </MenuItem>
        )}

        {assignment && (
          <MenuItem
            onClick={() => handleOpenFormDeleteAssignment(assignment.id)}
          >
            {t('label_deleteAssign')}
          </MenuItem>
        )}
        <MenuItem>
          <Link
            to={`/dashboard/devices/${id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {t('label_deviceHistory')}
          </Link>
        </MenuItem>
      </Popover>
    </>
  );
};
export default DeviceRow;
