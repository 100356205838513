import {
  AssignmentModel,
  AssignmentSchema,
} from 'shared/src/assignment.schema';
import { config } from '../config';

const route = 'assignments';

// GET all assignment
export const GET_ASSIGNMENT = async (token: string) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();

  return data;
};

// GET a assignment by ID
export const GET_ASSIGNMENT_BY_ID = async (token: string, id: number) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

// GET ASSIGNMENT BY DEVICE ID
export const GET_ASSIGNMENT_BY_DEVICE_ID = async (
  token: string,
  id: number
) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}/device`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

// POST create assignment
export const POST_CREATE_ASSIGNMENT = async (
  token: string,
  body: AssignmentSchema
) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

// PATCH update assignment
export const PATCH_UPDATE_ASSIGNMENT = async (
  token: string,
  body: AssignmentModel
) => {
  const {
    assignment_end_date,
    assignment_start_date,
    manager_first_name,
    manager_last_name,
    manager_auth0id,
    description,
    user_id,
  } = body;
  const response = await fetch(`${config.serverDomain}/${route}/${body.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      assignment_end_date,
      assignment_start_date,
      manager_first_name,
      manager_last_name,
      manager_auth0id,
      description,
      user_id,
    }),
  });
  const data = await response.json();
  return data;
};
