import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import KeyboardAltRoundedIcon from '@mui/icons-material/KeyboardAltRounded';
import LaptopChromebookRoundedIcon from '@mui/icons-material/LaptopChromebookRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import MemoryRoundedIcon from '@mui/icons-material/MemoryRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { blueGrey } from '@mui/material/colors';
import { DeviceDetailsSchema } from 'shared/src/device.schema';
import { FC } from 'react';

interface IDetailsList {
  deviceDetails: DeviceDetailsSchema;
}
const DetailsList: FC<IDetailsList> = ({ deviceDetails }) => {
  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}
    >
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: blueGrey[500],
            }}
          >
            <SettingsSuggestRoundedIcon fontSize="small" />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="CPU"
          secondary={deviceDetails.cpu}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: blueGrey[500],
            }}
          >
            <MemoryRoundedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="RAM"
          secondary={`${deviceDetails.ram} GB`}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: blueGrey[500],
            }}
          >
            <StorageRoundedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Storage"
          secondary={`${deviceDetails.storage} GB`}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: blueGrey[500],
            }}
          >
            <LaptopChromebookRoundedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Display"
          secondary={`${deviceDetails.display}"`}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: blueGrey[500],
            }}
          >
            <KeyboardAltRoundedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Keyboard"
          secondary={deviceDetails.keyboard}
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            sx={{
              bgcolor: blueGrey[500],
            }}
          >
            <AttachMoneyRoundedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Price"
          secondary={deviceDetails.price}
        />
      </ListItem>
    </List>
  );
};
export default DetailsList;
