import { DeviceModel, DeviceSchema } from 'shared/src/device.schema';
import { config } from '../config';
import { PartialDeep } from 'utils/types';

const route = 'devices';
// GET all devices
export const GET_DEVICES = async (token: string) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  return data;
};

// GET device by ID
export const GET_DEVICE_BY_ID = async (
  token: string,
  id: number | undefined
) => {
  const response = await fetch(`${config.serverDomain}/${route}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const data = await response.json();
  return data;
};

// POST create device
export const POST_CREATE_DEVICE = async (token: string, body: DeviceSchema) => {
  const response = await fetch(`${config.serverDomain}/${route}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};

// PATCH update device by ID
export const PATCH_UPDATE_DEVICE = async (
  token: string,
  body: PartialDeep<DeviceModel>
) => {
  const response = await fetch(`${config.serverDomain}/${route}/${body.id}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  return data;
};
