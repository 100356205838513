import { useEffect, useState } from 'react';
import { CatalogTable } from '../../components/sections/dashboard/catalog/catalogueTable';
import { Drawer } from '../../components/sections/dashboard/_common/drawer';
import { Box, Container } from '@mui/material';
import { CatalogForm } from '../../components/sections/dashboard/catalog/catalogForm';
import { CatalogueDeviceModel } from 'shared/src/catalogue.schema';
import { applySortTableFilter } from 'utils/table_utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectCatalogue } from 'store/catalogue/catalogue.selectors';
import { SearchBar } from 'components/searchBar';
import { selectRole } from 'store/role/role.selectors';
import ProtectedRoute from '../../components/protectedRoute';
import { Roles } from '../../components/enum/enumRoles';
import CatalogUserComponent from '../../components/sections/dashboard/catalog/catalogComponent';
import { AppDispatch } from 'store/store';
import { useAuth0 } from '@auth0/auth0-react';
import createDeviceCatalog from 'store/catalogue/use-case/createCatalogDevice';
import updateCatalogDevice from 'store/catalogue/use-case/updateCatalogDevice';
import TableHeader from 'components/tables/table/tableHeader';
import { ICreateData } from 'domain/catalog';
import { useTranslation } from 'react-i18next';
import { TableRowDataNotFound } from 'components/_Table';

const legend = [
  { id: 'picture', labelKey: 'label_picture', alignRight: false },
  { id: 'name', labelKey: 'label_name', alignRight: false },
  { id: 'model', labelKey: 'label_model', alignRight: false },
  { id: 'status', labelKey: 'label_status', alignRight: false },
  { id: 'details', labelKey: 'label_details' },
  { id: 'action', labelKey: 'label_action' },
];

const CatalogPage = () => {
  const role: string = useSelector(selectRole);
  const devices: CatalogueDeviceModel[] = useSelector(selectCatalogue);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<CatalogueDeviceModel | null>(null);
  const [method, setMethod] = useState<string | null>(null);
  const [dataFilter, setDataFilter] = useState<CatalogueDeviceModel[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { getAccessTokenSilently } = useAuth0();

  const toggleDrawer = (
    open: boolean,
    method: string,
    row: CatalogueDeviceModel | null
  ) => {
    if (!open) {
      setMethod(null);
      setDataForm(null);
    } else {
      setMethod(method);
      setDataForm(row);
    }
    setOpenDrawer(open);
  };

  useEffect(() => {
    setDataFilter(devices);
  }, [devices]);

  const resetState = () => {
    setOpenDrawer(false);
  };

  const { t } = useTranslation();

  const onChangeFilter = (event: { target: { value: string } }) => {
    const value = event.target.value.toLowerCase();
    const filteredData = applySortTableFilter<CatalogueDeviceModel>(
      devices,
      value
    );
    setDataFilter(filteredData);
  };

  const onSubmit = async (data: ICreateData) => {
    const token = await getAccessTokenSilently();
    const postFormData = {
      model: data.model,
      status: data.status,
      details: {
        id: data.detailsId,
        name: data.name,
        price: Number(data.price),
        picture: data.picture,
        display: data.display,
        storage: data.storage,
        cpu: data.cpu,
        ram: data.ram,
        keyboard: data.keyboard,
        description: data.description,
      },
    };
    const patchFormData = {
      id: data.id,
      ...postFormData,
    };
    if (method === 'POST') {
      const result = await dispatch(
        createDeviceCatalog({
          token,
          body: postFormData,
        })
      );
      if (result.payload.result.status === 'dataAlreadyExist') {
        alert(result.payload.msg);
      }
    } else if (method === 'PATCH') {
      dispatch(
        updateCatalogDevice({
          token,
          body: patchFormData,
        })
      );
    }
    resetState();
  };

  return (
    <ProtectedRoute role={Roles.USER}>
      <Container
        sx={{
          textAlign: 'initial',
        }}
      >
        <TableHeader
          icon={'navbar/ic_catalog'}
          title={t('label_catalog')}
          role={role}
          CTA={{
            text: t('label_newDevice'),
            action: () => toggleDrawer(true, 'POST', null),
          }}
        />

        <SearchBar
          placeholder={t('label_searchCatalogPage')}
          onChangeFilter={onChangeFilter}
        />

        {dataFilter.length > 0 ? (
          <Box>
            {role === Roles.USER ? (
              <CatalogUserComponent data={dataFilter} />
            ) : (
              <>
                <CatalogTable
                  legend={legend}
                  dataRows={dataFilter}
                  toggleDrawer={toggleDrawer}
                />
              </>
            )}
          </Box>
        ) : (
          <TableRowDataNotFound legend={legend} />
        )}

        <Drawer
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          component={
            <CatalogForm
              dataForm={dataForm}
              onSubmit={onSubmit}
            />
          }
        />
      </Container>
    </ProtectedRoute>
  );
};

export default CatalogPage;
