import { createAsyncThunk } from '@reduxjs/toolkit';
import { AssignmentSchema } from 'shared/src/assignment.schema';
import AppThunkApiConfig from 'store/thunk-config-api';
import { POST_CREATE_ASSIGNMENT } from 'api/assignment';

interface rejectValueError {
  msg: string;
}

const createAssignments = createAsyncThunk<
  AssignmentSchema,
  { token: string; body: AssignmentSchema },
  AppThunkApiConfig & { rejectValue: rejectValueError }
>(
  'assignments/createAssignments',
  async ({ token, body }, { rejectWithValue }) => {
    try {
      const devices = await POST_CREATE_ASSIGNMENT(token, body);
      return devices;
    } catch (e) {
      return rejectWithValue({
        msg: 'Error createAssignments',
      });
    }
  }
);

export default createAssignments;
